import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import {
  Card,
  Col,
  Container,
  Row,
  Figure,
  ListGroup,
  Form,
  Modal,
  Button,
} from "react-bootstrap";
import Profile from "../../images/single-profile.png";
import { FiMapPin, FiUser, FiArrowLeft, FiMail } from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCoachPass,
  deactivateCoach,
  deleteCoach,
  getCoachDetails,
} from "../../redux/actions/adminActions";
import dummyimg from "../../images/dummy_img.png";
import { Formik } from "formik";

export default function CoachDetail() {
  const FILE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const coach = useSelector((state) => state.coachSlice.coachDetails);
  const availability = useSelector((state) => state.coachSlice.availability);
  const [show, setShow] = useState(false);
  const [switchVal, setSwitch] = useState();
  const [delShow, setDelShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    dispatch(getCoachDetails(id));
  }, []);

  const handleDeactivate = () => {
    const deObj = {
      coach_id: id,
      status: coach?.deactivated == 0 ? 1 : 0,
    };
    dispatch(deactivateCoach(deObj));
    setSwitch((prev) => !prev);
    handleClose();
  };

  const handleAccDel = () => {
    dispatch(deleteCoach(id));
    navigate("/admin/coaches");
    setDelShow(false);
  };

  useEffect(() => {
    if (coach) setSwitch(coach?.deactivated == 1 ? true : false);
  }, [coach]);

  return (
    <>
      <Layout>
        <div className="backBtn">
          <Link onClick={() => navigate(-1)}>
            <h5 className="text-primary-clr backpad">
              <FiArrowLeft /> Back
            </h5>
          </Link>
        </div>
        <Container>
          <Row>
            <Col lg="12">
              <Card className="border-0 card-user-detail rounded-card mb-5">
                <Row>
                  <Figure className="user-info mt-3">
                    <Figure.Image
                      src={
                        coach?.profile_image
                          ? FILE_URL + coach?.profile_image
                          : dummyimg
                      }
                      className="border-0 profile-img"
                      roundedCircle
                    />
                    <Figure.Caption className="px-5 text-dark">
                      <h3 className="fw-bold mb-4">{coach?.full_name}</h3>
                      <h6 className="mb-3">
                        <span className="dslpnonmob">
                          <FiMapPin /> {coach?.address}
                        </span>
                      </h6>
                      <h6 className="mb-3">
                        <span className="dslpnonmob">
                          <FiUser />{" "}
                          {coach?.gender != undefined
                            ? coach?.gender == 0
                              ? "Female"
                              : coach?.gender == 1
                              ? "Male"
                              : coach?.gender == 2 && "Prefer not to say"
                            : "N/A"}
                        </span>
                      </h6>
                      <h6 className="mb-3">
                        <span className="dslpnonmob">
                          <FiMail /> {coach?.email}
                        </span>
                      </h6>
                    </Figure.Caption>
                    <div className="rate-box text-center">
                      <h6>Rate</h6>
                      <h2>€{parseFloat(coach?.rateperhour).toFixed(2)}</h2>
                    </div>
                  </Figure>
                </Row>

                <Row className="pt-4">
                  <Col lg="6" className="">
                    <h5 className="mb-3">Biography</h5>
                    <p>{coach?.coachBio}</p>
                  </Col>
                  <Col lg="6" className="">
                  <h5 className="mb-3">Certificate</h5>
                    <a
                      href={
                        coach?.pdf
                          ? FILE_URL + "/" + coach?.pdf
                          : coach?.certification
                          ? FILE_URL + "/" + coach?.certification
                          : dummyimg
                      }
                      target="_blank"
                    >
                      {/* <img className=" rounded-circle certificate-image"
                        src={
                          coach?.certification
                            ? FILE_URL + "/" + coach?.certification
                            : dummyimg
                        }
                        alt="user image"
                        // className="rounded-circle profile-image"
                      /> */}

                      {(coach?.certification || coach?.pdf) &&
                              (coach?.certification?.endsWith(".pdf") ||
                                coach?.pdf?.endsWith(".pdf")) ? (
                                <embed
                                className="rounded-circle profile-image"
                                  src={FILE_URL + "/" + (coach?.certification||coach?.pdf)}
                                  type="application/pdf"
                                  width="100%"
                                  height="100%"
                                />
                              ) : (
                                <img
                                  src={
                                    coach?.certification
                                      ? FILE_URL + "/" + coach?.certification
                                      : dummyimg
                                  }
                                  alt="user image"
                                  className="rounded-circle profile-image"
                                />
                              )}
                    </a>
                  </Col>
                </Row>

                <Row>
                  {availability?.length > 0 &&
                    availability?.map((av) => {
                      return (
                        <Col lg="6" className="">
                          <div className="pt-5">
                            <h6 className="mb-2 text-primary-clr">{av?.day}</h6>
                          </div>
                          <ListGroup>
                            {av?.timeArr?.map((time) => (
                              <ListGroup.Item>
                                {time?.opening} - {time?.closing}
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        </Col>
                      );
                    })}
                </Row>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Row className="set_height">
                <Col lg={6}>
                  <div className="card_small">
                    <div className="head">
                      <h2>De-Activate Account</h2>
                    </div>
                    <div className="toggle">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="You will not be able to receive messages, notifications for up to 24 hours."
                        onClick={handleShow}
                        checked={switchVal}
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="card_small">
                    <div className="head">
                      <h2>Delete Account</h2>
                    </div>
                    <div className="dlt_acount">
                      <h6>
                        Once you delete the account, there is no going back.
                        Please be certain.
                      </h6>
                      <button
                        onClick={() => setDelShow(true)}
                        className="btn btn-danger "
                      >
                        Delete my account
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col lg={6}>
              <div className="card_small">
                <div className="head">
                  <h2>Change Password</h2>
                </div>
                <Row className="pt-4">
                  <Formik
                    initialValues={{
                      password: "",
                      confirmPassword: "",
                      coach_id: id,
                    }}
                    validate={(values) => {
                      const errors = {};
                      if (!values?.password) {
                        errors.password = "Required*";
                      }
                      if (!values?.confirmPassword) {
                        errors.confirmPassword = "Required*";
                      }
                      if (values?.password != values?.confirmPassword) {
                        errors.confirmPassword = "Passwords doesn't match";
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      dispatch(changeCoachPass(values));
                      resetForm();
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Row>
                          <Col lg={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>New Password</Form.Label>
                              <Form.Control
                                type="password"
                                placeholder="Password"
                                name="password"
                                onChange={handleChange}
                                value={values?.password}
                              />
                              <span className="formik-errors">
                                {errors?.password &&
                                  touched?.password &&
                                  errors?.password}
                              </span>
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Confirm Password</Form.Label>
                              <Form.Control
                                type="password"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                onChange={handleChange}
                                value={values?.confirmPassword}
                              />
                              <span className="formik-errors">
                                {errors?.confirmPassword &&
                                  touched?.confirmPassword &&
                                  errors?.confirmPassword}
                              </span>
                            </Form.Group>
                          </Col>
                          <Col lg={12}>
                            <div className="sub_btn">
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-primary"
                              >
                                Submit
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    )}
                  </Formik>
                </Row>
              </div>
            </Col>
          </Row>

          <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Body>
              <p className="modal_head">
                Are you sure you want to Activate/Deactivate this account?
              </p>
            </Modal.Body>
            <Modal.Footer className="m-auto">
              <Button variant="primary" onClick={handleDeactivate}>
                Yes
              </Button>
              <Button variant="primary" onClick={handleClose}>
                No
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={delShow}
            onHide={() => setDelShow(false)}
            animation={false}
          >
            <Modal.Body>
              <p className="modal_head">
                Are you sure you want to Delete this account?
              </p>
            </Modal.Body>
            <Modal.Footer className="m-auto">
              <Button variant="primary" onClick={handleAccDel}>
                Yes
              </Button>
              <Button variant="primary" onClick={() => setDelShow(false)}>
                No
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </Layout>
    </>
  );
}
