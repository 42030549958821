import React from "react";
import Layout from "../components/Layout/Layout";
import { Col, Container, Row, Card, Image } from "react-bootstrap";
import dashbordbg from "../images/dasboard-bg.png";
import usericon from "../images/user-icon.png";
import discount from "../images/Discount.png";
import user2 from "../images/2 User.png";
import verifyuser from "../images/Vierify-user.png";
import GenderChart from "../components/elements/GenderChart";
import UserChart from "../components/elements/UserChart";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { dashboard } from "../redux/actions/adminActions";
import Form from "react-bootstrap/Form";

export default function Dashboard() {
  const dispatch = useDispatch();

  const dash = useSelector((state) => state.dashboardSlice.dashboard);

  useEffect(() => {
    dispatch(dashboard());
  }, []);

  console.log(dash, "dashboard");

  return (
    <>
      <Layout>
        <Container fluid>
          <Row lg={4} md={3} xs={1} className="mt-4">
            <Col>
              <Card className="text-white dasboard-card card-orange p-3">
                <Card.Img src={dashbordbg} alt="Card image" />
                <Card.ImgOverlay>
                  <Card.Text className="d-flex justify-content-between">
                    <div>
                      <Card.Title>Total Users</Card.Title>
                      <Card.Text>{dash?.totalUsers}</Card.Text>
                    </div>
                    <div className="card-icon-box-1 card-icon-orange">
                      <Image src={usericon} />
                    </div>
                  </Card.Text>
                </Card.ImgOverlay>
              </Card>
            </Col>
            <Col>
              <Card className="text-white dasboard-card card-blue p-3">
                <Card.Img src={dashbordbg} alt="Card image" />
                <Card.ImgOverlay>
                  <Card.Text className="d-flex justify-content-between">
                    <div>
                      <Card.Title>Total Coaches</Card.Title>
                      <Card.Text>{dash?.totalCoaches}</Card.Text>
                    </div>
                    <div className="card-icon-box-1 card-icon-blue">
                      <Image src={discount} />
                    </div>
                  </Card.Text>
                </Card.ImgOverlay>
              </Card>
            </Col>
            <Col>
              <Card className="text-white dasboard-card card-sky p-3">
                <Card.Img src={dashbordbg} alt="Card image" />
                <Card.ImgOverlay>
                  <Card.Text className="d-flex justify-content-between">
                    <div>
                      <Card.Title>Reflection Journey</Card.Title>
                      <Card.Text>{dash?.totalReflectionJournies}</Card.Text>
                    </div>
                    <div className="card-icon-box-1 card-icon-sky">
                      <Image src={user2} />
                    </div>
                  </Card.Text>
                </Card.ImgOverlay>
              </Card>
            </Col>
            <Col>
              <Card className="text-white dasboard-card  card-green p-3">
                <Card.Img src={dashbordbg} alt="Card image" />
                <Card.ImgOverlay>
                  <Card.Text className="d-flex justify-content-between">
                    <div>
                      <Card.Title>Total Meditation</Card.Title>
                      <Card.Text>{dash?.totalMeditations}</Card.Text>
                    </div>
                    <div className="card-icon-box-1 card-icon-green">
                      <Image src={verifyuser} />
                    </div>
                  </Card.Text>
                </Card.ImgOverlay>
              </Card>
            </Col>
          </Row>
          <section className="booking-details-box">
            <Container fluid>
              <div className="cmn-top-fields mt-2">
                <Row className="align-items-center">
                  <Col xxl={8} xl={8} lg={4} md={4}>
                    <h3 className="m-0">Booking Details </h3>
                  </Col>
                  <Col xxl={2} xl={2} lg={4} md={4}>
                    <Form.Group>
                      <Form.Control
                        type="date"
                        placeholder="Keyword Search.."
                      />
                    </Form.Group>
                  </Col>
                  <Col xxl={2} xl={2} lg={4} md={4}>
                    <Form.Select aria-label="Default select example">
                      <option>Today</option>
                      <option value="1">This week </option>
                      <option value="2">This Month </option>
                      {/* <option value="3">Three</option> */}
                    </Form.Select>
                  </Col>
                </Row>
              </div>
            </Container>
            <Row lg={3} md={4} xs={1} className="mt-4">
              <Col>
                <Card className="text-white dasboard-card card-orange p-3">
                  <Card.Img src={dashbordbg} alt="Card image" />
                  <Card.ImgOverlay>
                    <Card.Text className="d-flex justify-content-between">
                      <div>
                        <Card.Title>Total Booking</Card.Title>
                        <Card.Text>{dash?.total_appointments}</Card.Text>
                      </div>
                      <div className="card-icon-box-1 card-icon-orange">
                        <Image src={usericon} />
                      </div>
                    </Card.Text>
                  </Card.ImgOverlay>
                </Card>
              </Col>
              <Col>
                <Card className="text-white dasboard-card card-blue p-3">
                  <Card.Img src={dashbordbg} alt="Card image" />
                  <Card.ImgOverlay>
                    <Card.Text className="d-flex justify-content-between">
                      <div>
                        <Card.Title>Accepted</Card.Title>
                        <Card.Text>
                          {dash?.total_appointments_accepted}
                        </Card.Text>
                      </div>
                      <div className="card-icon-box-1 card-icon-blue">
                        <Image src={user2} />
                      </div>
                    </Card.Text>
                  </Card.ImgOverlay>
                </Card>
              </Col>
              <Col>
                <Card className="text-white dasboard-card  card-green p-3">
                  <Card.Img src={dashbordbg} alt="Card image" />
                  <Card.ImgOverlay>
                    <Card.Text className="d-flex justify-content-between">
                      <div>
                        <Card.Title>Completed</Card.Title>
                        <Card.Text>
                          {dash?.total_appointments_completed}
                        </Card.Text>
                      </div>
                      <div className="card-icon-box-1 card-icon-green">
                        <Image src={verifyuser} />
                      </div>
                    </Card.Text>
                  </Card.ImgOverlay>
                </Card>
              </Col>
            </Row>
          </section>
          <Row className="mt-4">
            <Col lg="6">
              <Card className="p-4 border-0 rounded-card mb-2">
                <h4>Gender Ratio</h4>
                <GenderChart genderData={dash?.gender} />
              </Card>
            </Col>

            <Col lg="6">
              <Card className="p-4 border-0 rounded-card mb-2">
                <h4>User Ratio</h4>
                <UserChart
                  verifyData={dash?.verified}
                  unverifiedData={dash?.unverified}
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}
