import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getVerificationUsers } from "../actions/adminActions";

const initialState = {
  users: null,
};

export const userVerifySlice = createSlice({
  name: "userVerifySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVerificationUsers.fulfilled, (state, { payload }) => {
      if (payload.success) {
        state.users = payload.data;
      } else if (!payload.success) {
        toast.error(payload?.message);
      }
    });
  },
});

export default userVerifySlice.reducer;
