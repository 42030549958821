import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  Row,
  Badge,
  Button,
  Form,
  Table,
  Stack,
  Modal,
} from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import Layout from "../components/Layout/Layout";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCoaches, verifyCoach } from "../redux/actions/adminActions";
import Pagination from "../components/elements/Pagination";
import dummy from "../images/dummy_img.png";
import { setUserId } from "firebase/analytics";
export default function Coaches() {
  const dispatch = useDispatch();
  const coaches = useSelector((state) => state.coachSlice.coaches);

  const limit = 5;
  const totalCoaches = useSelector((state) => state.coachSlice.totalCoaches);
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState("");
  const [page, setPage] = useState(1);
  const [show, setshow] = useState(false);
  const [showRej, setshowRej] = useState(false);
  const [id, setId] = useState(null);

  useEffect(() => {
    dispatch(getCoaches({ page, limit, search: searchData }));
  }, [page, searchData]);

  const handleEnter = (e) => {
    if (e.key == "Enter") {
      setSearchData(search);
      setPage(1);
    }
  };

  const handleVerify = (id, status) => {
    dispatch(verifyCoach({ id, status })).then((res) => {
      if (res?.payload?.success) {
        setshow(false);
        setshowRej(false);
      }
    });
  };

  const statusObj = {
    0: { css: "warning", text: "PENDING" },
    1: { css: "success", text: "VERIFIED" },
    2: { css: "danger", text: "REJECTED" },
  };

  console.log(coaches, "coach");

  return (
    <>
      <Layout>
        <Container fluid>
          <Row>
            <Card className="border-0 p-4 rounded-card user-section mt-4">
              <Stack
                className="user-table-align table-head"
                direction="horizontal"
                gap={3}
              >
                <div className="p-2">
                  <h3>Coaches</h3>
                </div>
                <div className="p-2 ms-auto">
                  <div className="position-relative m-2 ">
                    <Form.Control
                      type="search"
                      placeholder="Search..."
                      className="serch-feild"
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyDown={handleEnter}
                    />
                    {!search && <FiSearch className="searchicon2" />}
                  </div>
                </div>
              </Stack>
              <Table
                responsive
                variant=""
                className="border-0 custom-table coaches-table mt-3"
              >
                <thead className="first-td">
                  <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Certificate</th>
                    <th>Gender</th>
                    <th>Location</th>
                    <th className="width-set">Rate</th>
                    <th>Status </th>
                    <th colSpan={3}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {coaches?.length > 0 &&
                    coaches?.map((coach, i) => {
                      return (
                        <tr key={coach?._id}>
                          <td>{i + 1 + (page - 1) * limit}</td>
                          <td>{coach?.full_name}</td>
                          <td>
                            <a
                              href={
                                coach?.pdf
                                  ? BASE_URL + "/" + coach?.pdf
                                  : coach?.certification
                                  ? BASE_URL + "/" + coach?.certification
                                  : dummy
                              }
                              target="_blank"
                            >
                              {(coach?.certification || coach?.pdf) &&
                              (coach?.certification?.endsWith(".pdf") ||
                                coach?.pdf?.endsWith(".pdf")) ? (
                                <embed
                                className="rounded-circle profile-image"
                                  src={BASE_URL + "/" + (coach?.certification||coach?.pdf)}
                                  type="application/pdf"
                                  width="100%"
                                  height="100%"
                                />
                              ) : (
                                <img
                                  src={
                                    coach?.certification
                                      ? BASE_URL + "/" + coach?.certification
                                      : dummy
                                  }
                                  alt="user image"
                                  className="rounded-circle profile-image"
                                />
                              )}
                            </a>
                          </td>
                          <td>
                            {coach?.gender == 0
                              ? "Female"
                              : coach?.gender == 1
                              ? "Male"
                              : coach?.gender == 2
                              ? "Prefer not to say"
                              : "N/A"}
                          </td>
                          <td>{coach?.address || "N/A"}</td>
                          {coach?.rateperhour ? (
                            <td>
                              <span className="width-set">
                                €{coach?.rateperhour}
                              </span>
                            </td>
                          ) : (
                            <td>N/A</td>
                          )}

                          <td>
                            <Badge bg={statusObj[coach?.admin_verified]?.css}>
                              {statusObj[coach?.admin_verified]?.text}
                            </Badge>
                          </td>
                          <td>
                            <Stack
                              direction="horizontal"
                              className="table-btn"
                              gap={3}
                            >
                              {coach?.admin_verified == 0 && (
                                <Button
                                  onClick={() => {
                                    setshow(true);
                                    setId(coach?._id);
                                    // handleVerify(coach?._id, 1)
                                  }}
                                  variant="primary"
                                >
                                  Accept
                                </Button>
                              )}
                              {coach?.admin_verified == 0 && (
                                <Button
                                  onClick={() => {
                                    setshowRej(true);
                                    setId(coach?._id);
                                    // handleVerify(coach?._id, 1)
                                  }}
                                  variant="danger"
                                >
                                  Reject
                                </Button>
                              )}
                              <Link
                                to={`/admin/coachdetail/${coach?._id}`}
                                className="text-light"
                              >
                                <Button variant="light">View</Button>
                              </Link>
                            </Stack>
                          </td>
                        </tr>
                      );
                    })}
                  {coaches?.length == 0 && "Content not available yet."}
                </tbody>
              </Table>
              <Pagination
                totalStuff={totalCoaches}
                limit={limit}
                setPage={setPage}
              />
            </Card>
          </Row>

          <Modal show={show} onHide={() => setshow(false)} animation={false}>
            <Modal.Body>
              <p className="modal_head">
                Are you sure you want to Accept this Request?
              </p>
            </Modal.Body>
            <Modal.Footer className="m-auto">
              <Button variant="primary" onClick={() => handleVerify(id, 1)}>
                Yes
              </Button>
              <Button variant="primary" onClick={() => setshow(false)}>
                No
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showRej}
            onHide={() => setshowRej(false)}
            animation={false}
          >
            <Modal.Body>
              <p className="modal_head">
                Are you sure you want to Reject this Request?
              </p>
            </Modal.Body>
            <Modal.Footer className="m-auto">
              <Button variant="primary" onClick={() => handleVerify(id, 2)}>
                Yes
              </Button>
              <Button variant="primary" onClick={() => setshowRej(false)}>
                No
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </Layout>
    </>
  );
}
