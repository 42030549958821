import React, { useState } from "react";
import { Container, Row, Card, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { FiArrowLeft, FiPlus } from "react-icons/fi";
import Layout from "../Layout/Layout";
import HTMLEditor from "../FAQs/HTMLEditor";
import { createhelp } from "../../redux/actions/adminActions";

export default function CreateHelp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [htmlContent, setHtmlContent] = useState();
  const [blockContent, setBlockContent] = useState();
  const [title, setTitle] = useState();
  const [assignedTo, setAssignedTo] = useState();

  const handleSubmit = () => {
    if (!title || !htmlContent || !assignedTo) {
      toast.warning("Please provide all fields");
    } else {
      dispatch(
        createhelp({
          title,
          assignedTo,
          html: htmlContent,
          blockContent,
        })
      )
        .then((response) => {
          if (response.payload.success) {
            toast.success(response.payload.message);
            navigate("/admin/help");
          } else {
            toast.warning(response.payload.message);
          }
        })
        .catch((err) => toast.error(err.message));
    }
  };

  return (
    <>
      <Layout>
        <Container fluid>
        <div className="backBtn">
          <a onClick={() => navigate(-1)}>
            <h5 className="text-primary-clr backpad cursor-pointer">
              <FiArrowLeft /> Back
            </h5>
          </a>
        </div>
          <Row>
            <Card className="rounded-card faqs">
              <div className="top-flex justify-content-between">
                <div>
                  <h2>Add New Help</h2>
                </div>
              </div>
              <Container>
              <div className="faq-label">
                  <label>Assigned To</label>
                  <br />
                  <select
                    className="form-control"
                    name="assigned_to"
                    onChange={({ target: { value } }) => setAssignedTo(value)}
                    value={assignedTo}
                  >
                    <option selected>Select Assigned To</option>
                    <option value="1">User</option>
                    <option value="2">Coach</option>
                  </select>

                </div>
                <div className="faq-label">
                  <label>Title</label>
                  <br />
                  <input
                    className="form-control"
                    placeholder="Type here..."
                    onChange={({ target: { value } }) => setTitle(value)}
                    value={title}
                  />
                </div>
                <div className="faq-label">
                  <label>Content</label>
                  <HTMLEditor
                    setHtmlContent={setHtmlContent}
                    setBlockContent={setBlockContent}
                  />
                </div>
              </Container>

              <Button onClick={handleSubmit} variant="primary" size="lg">
                Create
                <FiPlus />
              </Button>
            </Card>
          </Row>
        </Container>
      </Layout>
    </>
  );
}
