import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addNotification,
  deleteNotification,
  deletefaq,
  deletehelp,
  getImprint,
  getNotifications,
  getfaqDetails,
  getfaqs,
  gethelpDetails,
  gethelps,
} from "../actions/adminActions";

const initialState = {
  notifications: null,
  faqs: null,
  faqdetails: null,
  helps: null,
  helpdetails: null,
  imprint:null
};

export const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.notifications = payload.data;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(addNotification.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.notifications.push(payload.data);
          toast.success(payload?.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deleteNotification.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.notifications = state.notifications?.filter(
            (not) => not?._id != payload.data?._id
          );
          toast.success(payload?.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getfaqs.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.faqs = payload.data;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deletefaq.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.faqs = state.faqs.filter(
            (faq) => faq?._id != payload.data?._id
          );
          toast.success(payload.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getfaqDetails.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.faqdetails = payload?.data;
          // toast.success(payload.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(gethelps.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.helps = payload.data;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deletehelp.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.helps = state.helps.filter(
            (help) => help?._id != payload.data?._id
          );
          toast.success(payload.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(gethelpDetails.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.helpdetails = payload?.data;
          // toast.success(payload.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getImprint.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.imprint = payload?.data;
          // toast.success(payload.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      });
  },
});

export default notificationSlice.reducer;
