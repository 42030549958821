import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createCategory,
  createCourseCategory,
  deleteCategoryById,
  deleteCourseCategory,
  getAudioFile,
  getCategory,
  getCategorybyid,
  getCourseCategories,
} from "../actions/adminActions";
const initialState = {
  courseCats: null,
  category: [],
  audioFile:null,
  categoryData:null
};

export const meditationSlice = createSlice({
  name: "meditationSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCourseCategories.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.courseCats = payload.data;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(createCourseCategory.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.courseCats?.push(payload.data);
          toast.success(payload?.message)
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deleteCourseCategory.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.courseCats = state.courseCats?.filter(
            (cat) => cat?._id != payload.data._id
          );
          toast.success(payload?.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      
      .addCase(getCategory.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.category = payload.data;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(createCategory.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.category?.push(payload.data);
          toast.success(payload?.message)
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deleteCategoryById.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.category = state.category.filter((item)=> item?._id?.toString() != payload?.data?._id?.toString());
          toast.success(payload?.message)
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      

      .addCase(getAudioFile.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.audioFile = payload?.data;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getCategorybyid.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.categoryData = payload?.data;
        } 
        
      });
  },
});

export default meditationSlice.reducer;
