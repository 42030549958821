import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function UserChart({ verifyData, unverifiedData }) {
  const data = {
    labels: [
      `Verified Users ${verifyData}`,
      `Unverified Users ${unverifiedData}`,
    ],
    datasets: [
      {
        // label: "# of Votes",
        data: [verifyData, unverifiedData],
        backgroundColor: ["#6DBBFA", "#00D89D"],
        borderColor: ["#6DBBFA", "#00D89D "],
        borderWidth: 1,
      },
    ],
  };

  return <Doughnut className="gender-chart" data={data} />;
}
