import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { deleteCourse, editCourse, getCourses } from "../actions/adminActions";

const initialState = {
  courses: null,
};

export const courseSlice = createSlice({
  name: "courseSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCourses.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.courses = payload.data;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deleteCourse.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.courses = state.courses?.filter(
            (course) => course?._id != payload.data._id
          );
          toast.success(payload?.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(editCourse.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.courses = state.courses?.map((course) =>
            course?._id == payload.data._id ? payload.data : course
          );
          toast.success(payload?.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      });
  },
});

export default courseSlice.reducer;
