import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import htmlformatter from "html-formatter";
import {
  Card,
  Container,
  Row,
  Form,
  Button,
  Table,
  Stack,
  Modal,
} from "react-bootstrap";
import { FiSearch, FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { deletefaq, getfaqs } from "../redux/actions/adminActions";
import { Link } from "react-router-dom";
export default function FAQs() {
  const dispatch = useDispatch();
  const [delShow, setDelShow] = useState(false);
  const [delId, setDelId] = useState();
  const [search, setSearch] = useState("");

  const faqs = useSelector((state) => state.notificationSlice.faqs);

  useEffect(() => {
    dispatch(search ? getfaqs( search ) : getfaqs());
  }, [search]);

  const handleDelete = () => {
    dispatch(deletefaq(delId));
    setDelShow(false);
  };

  return (
    <>
      <Layout>
        <Container fluid>
          <Row>
            <div className="d-flex justify-content-end mb-4 theme mt-5">
              <Link to="/admin/createfaqs">
                <Button variant="primary" size="lg">
                  <FiPlus /> Add
                </Button>
              </Link>
            </div>
            <Card className="border-0 p-4 rounded-card user-section notify-sec ">
              <Stack
                className="user-table-align"
                direction="horizontal"
                gap={3}
              >
                <div className="p-2">
                  <h3>FAQs</h3>
                </div>
                <div className="p-2 ms-auto">
                  <div className="position-relative m-2 align-items-center">
                    <Form.Control
                      type="search"
                      placeholder="Search..."
                      className="serch-feild"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    {!search && <FiSearch className="searchicon2" />}
                  </div>
                </div>
              </Stack>
              <Table
                responsive
                variant=""
                className="border-0 custom-table mt-3"
              >
                <thead className="first-td">
                  <tr>
                    <th>S.No</th>
                    <th>Title</th>
                    <th className="width-set">Assigned To</th>
                    {/* <th>Description</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {faqs?.map((faq, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{faq?.title}</td>
                      <td>
                        {faq?.assignedTo == 1
                          ? "User"
                          : faq?.assignedTo == 2
                          ? "Coach"
                          : "N/A"}
                      </td>
                      {/* <td>{faq?.html.substring(0, faq?.html.length)}</td> */}
                      {/* <td>{htmlformatter.render(faq?.html)}</td> */}
                      <td>
                        <Stack
                          direction="horizontal"
                          className="table-btn"
                          gap={3}
                        >
                          <Link to={`/admin/editfaqs/${faq?._id}`}>
                            <Button variant="success">Edit</Button>
                          </Link>
                          <Button
                            onClick={() => {
                              setDelShow(true);
                              setDelId(faq?._id);
                            }}
                            variant="success"
                          >
                            Delete
                          </Button>
                        </Stack>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {faqs?.length == 0 && (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      {" "}
                      <div className="no-data">Content not available yet.</div>
                    </td>
                  </tr>
                )}
              </Table>
            </Card>
          </Row>
        </Container>
      </Layout>

      {/* Delete Modal */}

      {delId && (
        <Modal
          show={delShow}
          onHide={() => setDelShow(false)}
          className="notify-model"
        >
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <Modal.Title className="text-center mb-5">Delete FAQ?</Modal.Title>
          <Modal.Body className=" border-0 text-center">
            <p>Are you sure you want to delete this FAQ?</p>
          </Modal.Body>
          <Modal.Footer className="m-auto border-0">
            <Button
              className="mb-5"
              variant="primary"
              size="lg"
              onClick={handleDelete}
            >
              Yes
            </Button>
            <Button
              className="mb-5"
              variant="primary"
              size="lg"
              onClick={() => setDelShow(false)}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
