import { useEffect, useState } from "react";
import {
  Card,
  Container,
  Row,
  Form,
  Button,
  Table,
  Badge,
  DropdownButton,
  Stack,
  Dropdown,
} from "react-bootstrap";
import { FiArrowDown } from "react-icons/fi";
import userimg from "../images/profile-user.png";
import { FiSearch } from "react-icons/fi";
import Layout from "../components/Layout/Layout";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeUserStatus, getUsers, getUsersdownload } from "../redux/actions/adminActions";
import dummy from "../images/dummy_img.png";
import Pagination from "../components/elements/Pagination";
import DownloadCsv from "../components/elements/DownloadCsv";

export default function User() {
  const dispatch = useDispatch();
  const limit = 5;
  const users = useSelector((state) => state.userMgmtSlice.users);
  const totalUsers = useSelector((state) => state.userMgmtSlice.totalUsers);
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState("");
  const [page, setPage] = useState(1);
  const [downloadData, setDownloadData] = useState(null);

  useEffect(() => {
    dispatch(getUsers({ page, limit, search: searchData }));
  }, [page, searchData]);

  useEffect(()=>{
    console.log("hihihihihihihi")
    const fetchData=async()=>{
      let data= await dispatch(getUsersdownload())
      setDownloadData(data)

    }
    fetchData()

  },[])

  const handleEnter = (e) => {
    if (e.key == "Enter") {
      setSearchData(search);
    }
  };

  const handleStatusChange = (id, status) => {
    dispatch(changeUserStatus({ id, status }));
  };

  // console.log(downloadData?.payload?.data?.users,"doen")

  const data = downloadData?.payload?.data?.users?.map((item, i) => {
    return {
      "S.No.": i + 1,
      Name: item?.full_name,
      Location: item?.address ? item?.address?.split("-")[0] : "-",
      "Verified/ UN status":
        item?.email_verified == 1 ? "VERIFIED" : "UN-VERIFIED",
      Gender:
        item?.gender != undefined
          ? item?.gender == 0
            ? "Female"
            : item?.gender == 1
            ? "Male"
            : item?.gender == 2 && "Prefer not to say"
          : "-",

      Status:
        item?.status == 1
          ? "Active"
          : item?.status == 2
          ? "Temporarily Banned"
          : item?.status == 3 && "Permanent Banned",
    };
  });

  console.log(users,"users")
  return (
    <>
      <Layout>
        <Container fluid>
          <Row>
            <Stack className="mb-3 mt-4" direction="horizontal" gap={1}>
              {/* <div className="p-2 ms-auto"><Button className="text-primary-clr" size="lg" variant=""><FiFilter/></Button>{' '}</div> */}
              <div className="p-2 ms-auto">
                <DownloadCsv data={data} />{" "}
              </div>
            </Stack>
            <Card className="border-0 p-4 rounded-card user-section ">
              <Stack
                className="user-table-align"
                direction="horizontal"
                gap={3}
              >
                <div className="p-2">
                  <h3>User Management</h3>
                </div>
                <div className="p-2 ms-auto">
                  <div className="position-relative m-2">
                    <Form.Control
                      type="search"
                      placeholder="Search..."
                      className="serch-feild"
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyDown={handleEnter}
                    />
                    {!search && <FiSearch className="searchicon2" />}
                  </div>
                </div>
              </Stack>
              <Table
                responsive
                variant=""
                className="border-0 custom-table mt-3"
              >
                <thead className="first-td">
                  <tr>
                    <th>S.No.</th>
                    <th>Name</th>
                    {/* <th>Age</th> */}
                    <th>Location</th>
                    <th>Verified/ UN status</th>
                    <th>Profile Image</th>

                    <th>Gender</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users?.length > 0 &&
                    users?.map((user, i) => {
                      return (
                        <tr key={user?._id}>
                          <td>{i + 1 + (page - 1) * limit}</td>
                          <td>
                            <Link to={`/admin/userdetails/${user?._id}`}>
                              {user?.full_name || "N/A"}
                            </Link>
                            {/* {user?.full_name} */}
                          </td>
                          {/* <td>{user?.age ? user?.age : "-"}</td> */}
                          <td>
                            {user?.address
                              ? user.address == "undefined"
                                ? "N/A"
                                : user?.address?.split("-")[0]
                              : "N/A"}
                          </td>
                          <td>
                            <Badge
                              bg={
                                user?.email_verified == 1 ? "success" : "danger"
                              }
                            >
                              {user?.email_verified == 1
                                ? "VERIFIED"
                                : "UN-VERIFIED"}
                            </Badge>
                          </td>
                          <td>
                            <img
                              src={
                                user?.profile_image
                                  ? BASE_URL + "/" + user?.profile_image
                                  : dummy
                              }
                              alt="user image"
                              className="rounded-circle profile-image"
                            />
                          </td>

                          <td>
                            {user?.gender != undefined
                              ? user?.gender == 0
                                ? "Female"
                                : user?.gender == 1
                                ? "Male"
                                : user?.gender == 2 && "Prefer not to say"
                              : "N/A"}
                          </td>
                          <td>
                            <Badge bg="primary">
                              {user?.status == 1
                                ? "ACTIVE"
                                : user?.status == 2
                                ? "TEMPORARILY BANNED"
                                : user?.status == 3 && "PERMANENT BANNED"}
                            </Badge>
                          </td>
                          <td>
                            <DropdownButton
                              id="dropdown-item-button "
                              title={
                                user?.status == 1
                                  ? "Active"
                                  : user?.status == 2
                                  ? "Temporarily Banned"
                                  : user?.status == 3 && "Permanent Banned"
                              }
                              className="border-0 secondary-drop-area"
                            >
                              {[
                                { key: 1, text: "Active" },
                                { key: 2, text: "Temporarily Banned" },
                                { key: 3, text: "Permanent Banned" },
                              ]?.map((status) => {
                                return user?.status == status?.key ? (
                                  <Dropdown.ItemText>
                                    {status?.text}
                                  </Dropdown.ItemText>
                                ) : (
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleStatusChange(user?._id, status?.key)
                                    }
                                    as="button"
                                  >
                                    {status?.text}
                                  </Dropdown.Item>
                                );
                              })}
                            </DropdownButton>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
                {users?.length == 0 && "Content not available yet."}
                {users?.length == 0 && (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      {" "}
                      <div className="no-data">Content not available yet.</div>
                    </td>
                  </tr>
                )}
              </Table>

              <Pagination
                totalStuff={totalUsers}
                limit={limit}
                setPage={setPage}
              />
            </Card>
          </Row>
        </Container>
      </Layout>
    </>
  );
}
