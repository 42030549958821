import React, { useState } from "react"
import Layout from "../components/Layout/Layout"
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap"
import { changePass } from "../redux/actions/adminActions";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";


export default function ChangePassword() {


    const dispatch = useDispatch();

    const validate = (values) => {
        const errors = {};
        if (!values.new_password) {
            errors.new_password = "Required";
        } else if (values.new_password != values.confirm_password) {
            errors.confirm_password = "Password Doesn't match";
        }

        return errors;
    };
    const formik = useFormik({
        initialValues: {
            old_password: "",
            new_password: "",
            confirm_password: "",
        },
        validate,
      
        onSubmit: (values) => {
          
            dispatch(changePass({ password: values?.confirm_password, old_password: values?.old_password })).then((data) => {
                if (data?.payload?.success) {
                    formik.resetForm();
                    toast.success(data?.payload?.message)
                }
            });

        },
    });

    return (
        <>
            <Layout>
                <Container fluid>
                    <Row className="justify-content-md-center">
                        <Col lg="6" md="auto">
                            <Card className="border-0 p-5 rounded-card mt-5">
                                <h2 className="text-center">Change Password</h2>

                                <Form onSubmit={formik.handleSubmit} className="change-password-form px-5 mt-5">
                                    <Row className="mb-4">
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label>Old Password</Form.Label>
                                            <Form.Control 
                                            type="password" 
                                            placeholder="Old Password" 
                                            name="old_password"
                                            onChange={formik.handleChange}
                                            value={formik.values.old_password}
                                            />
                                             {formik.errors.old_password && (
                                        <div className="error_valid">
                                            {formik.errors.old_password}
                                        </div>
                                    )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-4">
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label>New Password</Form.Label>
                                            <Form.Control 
                                            type="password" 
                                            placeholder="New Password"
                                            name="new_password"
                                            onChange={formik.handleChange}
                                            value={formik.values.new_password}
                                             />
                                              {formik.errors.new_password && (
                                        <div className="error_valid">
                                            {formik.errors.new_password}
                                        </div>
                                    )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-4">
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control 
                                            type="password" 
                                            placeholder="Confirm Password"
                                            name="confirm_password"
                                            onChange={formik.handleChange}
                                            value={formik.values.confirm_password}
                                            />
                                                {formik.errors.confirm_password && (
                                        <div className="error_valid">
                                            {formik.errors.confirm_password}
                                        </div>
                                    )}
                                        </Form.Group>
                                    </Row>
                                    <Form.Group className="mt-5  mb-5 model-btn text-center" controlId="formGridAddress2">
                                        <Button type="submit" variant="primary" size="lg" className="add-btn-shadow">
                                            Submit
                                        </Button>
                                    </Form.Group>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Layout>
        </>
    )
}