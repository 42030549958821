import { configureStore } from "@reduxjs/toolkit";
import userMgmtReducer from "./reducers/userMgmtSlice";
import userVerifyReducer from "./reducers/userVerifySlice";
import reportReducer from "./reducers/reportsSlice";
import notificationReducer from "./reducers/notificationSlice";
import supportReducer from "./reducers/supportSlice";
import coachReducer from "./reducers/coachSlice";
import reflectionReducer from "./reducers/reflectionSlice";
import meditationReducer from "./reducers/meditationSlice";
import courseReducer from "./reducers/courseSlice";
import dashboardReducer from "./reducers/dashboardSlice";

export const store = configureStore({
  reducer: {
    userMgmtSlice: userMgmtReducer,
    userVerifySlice: userVerifyReducer,
    reportsSlice: reportReducer,
    notificationSlice: notificationReducer,
    supportSlice: supportReducer,
    coachSlice: coachReducer,
    reflectionSlice: reflectionReducer,
    meditationSlice: meditationReducer,
    courseSlice: courseReducer,
    dashboardSlice: dashboardReducer,
  },
});
