import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function GenderChart({ genderData }) {
  const data = {
    labels: [
      `Male ${genderData?.male}`,
      `Female ${genderData?.female}`,
      `Prefer not to say ${genderData?.others}`,
    ],
    datasets: [
      {
        // label: "# of Votes",
        data: [genderData?.male, genderData?.female, genderData?.others],
        backgroundColor: ["#6DBBFA", "#FF8F56", "#00D89D"],
        borderColor: ["#6DBBFA", "#FF8F56", "#00D89D "],
        borderWidth: 1,
      },
    ],
  };

  return <Doughnut className="gender-chart" data={data} />;
}
