import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Container,
  Row,
  Card,
  ListGroup,
  Image,
  Button,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import { FiPlus } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import Deleteicon from "../images/delete_icon.svg";
import Editicon from "../images/edit_icon.svg";
import Playicon from "../images/play_icon.svg";
import playpop from "../images/plypop.png";
import { useDispatch, useSelector } from "react-redux";
import {
  createCategory,
  createCourse,
  createCourseCategory,
  deleteCourseCategory,
  getCategory,
  getCourseCategories,
  deleteCategoryById,
  getCategorybyid,
  EditCategorybyid,
} from "../redux/actions/adminActions";
import { toast } from "react-toastify";

export default function Category() {
  const catData = useSelector((state) => state.meditationSlice.categoryData);

  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [showCat, setShowCat] = useState(false);
  const [flag,setFlag] = useState(false)
  const [showCatEdit, setshowCatEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [showDel, setShowDel] = useState(false);
  const [delId, setDelId] = useState();
  const [color, setcolor] = useState(catData?.color ? catData?.color : "");
  const [courseCatId, setCourseCatId] = useState();
  const [showCourse, setShowCourse] = useState(false);
  const [courseTitle, setCourseTitle] = useState("");
  const [audio, setAudio] = useState();
  const [image, setImage] = useState();
  const [prevImg, setPrevImg] = useState();
  const [image2, setImage2] = useState();
  const [prevImg2, setPrevImg2] = useState();
  const [catTitle, setCatTitle] = useState(
    catData?.title ? catData?.title : ""
  );
  const [catTitle2, setCatTitle2] = useState(
    catData?.germen_title ? catData?.germen_title : ""
  );
  const [descState, setDescState] = useState(
    catData?.desc ? catData?.desc : ""
  );
  const [descState2, setDescState2] = useState(
    catData?.germen_desc ? catData?.germen_desc : ""
  );

  const courseCats = useSelector((state) => state.meditationSlice.category);
  console.log(courseCats, "courdse");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategory());
  }, [flag]);

  // useEffect(() => {
  //   const fetchdata=async()=>{
  //     try {
  //       let data =await dispatch(getCategorybyid(editId));

  //       if(data?.payload?.success){

  //         setCatTitle(data?.payload?.data?.title)
  //         setCatTitle2(data?.payload?.data?.germen_title)
  //         setcolor(data?.payload?.data?.color)
  //         setDescState(data?.payload?.data?.desc == "undefined" ? "" : data?.payload?.data?.desc)
  //         setDescState2(data?.payload?.data?.germen_desc == "undefined" ? "" : data?.payload?.data?.germen_desc)
  //         // setImage(data?.payload?.data?.category_img)
  //         setPrevImg(`https://mindrepublic-api.bosselt.com${data?.payload?.data?.category_img}`)
  //       }
  //     } catch (error) {

  //     }

  //   }
  //   fetchdata()

  // }, [editId]);

  const handleDeleteCat = () => {
    dispatch(deleteCategoryById(delId)).then(() => setShowDel(false));
  };

  const handleImgUpload = (e) => {
    setPrevImg(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  const handleImgUpload2 = (e) => {
    setPrevImg2(URL.createObjectURL(e.target.files[0]));
    setImage2(e.target.files[0]);
  };

  const handleCreateCategory = () => {
    if (catTitle == "" || catTitle2 == "") {
      toast.warning("Category Title is required");
      return;
    }
    if (catTitle != "" && catTitle2 != "" && !image) {
      toast.warning("Image File is required");
      return;
    }

    const formData = new FormData();

    formData.append("category_img", image);
    // formData.append("germen_image", image2);
    formData.append("title", catTitle);
    formData.append("germen_title", catTitle2);
    formData.append("desc", descState);
    formData.append("germen_desc", descState2);
    formData.append("color", color);

    dispatch(createCategory(formData));
    setShowCat(false);
    setCatTitle();
    setCatTitle2();
    setImage();
    setPrevImg();
    setDescState();
    setDescState2();
    dispatch(getCategory());
  };

  const handleEditCategory = () => {
    if (catTitle == "" || catTitle2 == "") {
      toast.warning("Category Title is required");
      return;
    }
    // if (catTitle != "" && catTitle2 != "" && !image) {
    //   toast.warning("Image File is required");
    //   return;
    // }

    const formData = new FormData();

    formData.append("category_img", image);
    // formData.append("germen_image", image2);
    formData.append("title", catTitle);
    formData.append("germen_title", catTitle2);
    formData.append("desc", descState);
    formData.append("germen_desc", descState2);
    formData.append("color", color);
    formData.append("id", editId);

    dispatch(EditCategorybyid(formData));
    setshowCatEdit(false);
    setCatTitle();
    setCatTitle2();
    setImage();
    setPrevImg();
    setDescState();
    setDescState2();
    setFlag(!flag)
    dispatch(getCategory());
  };


  console.log(image)

  return (
    <>
      <Layout>
        <Container fluid>
          <Row>
            <Card className="border-0 card-user-detail rounded-card meditation-info px-4 mt-4">
              <div className="top-flex justify-content-between">
                <div>
                  <h2>Self Reflection</h2>
                </div>
                <div>
                  <Button
                    className="add-category-btn"
                    variant="primary"
                    onClick={() => setShowCat(true)}
                  >
                    <FiPlus /> Add New Category
                  </Button>
                </div>
              </div>
              {/* <h5 className="py-2 mt-3">Courses in Meditation</h5> */}
              <Row className="mt-4">
                {courseCats?.length > 0 ? (
                  courseCats?.map((cat) => {
                    return (
                      <Col
                        key={cat?._id}
                        xxl={3}
                        xl={4}
                        lg={4}
                        md={6}
                        className="table-card mb-5 "
                      >
                        <Card className="text-center card-meditation">
                          <Link 
                          // to={`/admin/courses/${cat?._id}/${cat?.title}`}
                          to={`/admin/self-reflection/${cat?._id}`}
                          >
                            <Card.Header className="cursor-pointer">
                              <img src={BASE_URL + cat?.category_img} />
                            </Card.Header>
                          </Link>

                          <Card.Body className="card-theme-med pb-0">
                            <Card.Title>{cat?.title}</Card.Title>
                            {/* <Card.Text>{cat?.course_count} courses</Card.Text> */}
                            <Link
                              to={`/admin/self-reflection/${cat?._id}`}
                              className="card-btn-add"
                            >
                              Add Reflection
                            </Link>
                            <div
                              onClick={() => {
                                setShowDel(true);
                                setDelId(cat?._id);
                              }}
                              className="card-delete"
                            >
                              <Link>Delete</Link>
                            </div>
                            <div
                              onClick={() => {
                                setshowCatEdit(true);
                                setEditId(cat?._id);
                                setCatTitle(cat?.title);
                                setCatTitle2(cat?.germen_title);
                                setcolor(cat?.color);
                                setDescState(
                                  cat?.desc == "undefined"
                                    ? "" :
                                     cat?.desc
                                );
                                setDescState2(
                                  cat?.germen_desc ==
                                    "undefined"
                                    ? ""
                                    :
                                     cat?.germen_desc
                                );
                                // setImage(data?.payload?.data?.category_img)
                                setPrevImg(
                                  `https://mindrepublic-api.bosselt.com${cat?.category_img}`
                                );
                                setImage(cat?.category_img)
                              }}
                              className="card-delete"
                            >
                              <Link>Edit</Link>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })
                ) : (
                  <p>No content available yet!</p>
                )}
              </Row>
            </Card>
          </Row>
        </Container>
      </Layout>

      {/* Delete Modal */}

      {delId && (
        <Modal
          show={showDel}
          onHide={() => setShowDel(false)}
          className="text-center meditation-model modal-content-80"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton className="border-0 p-0"></Modal.Header>
          <Modal.Title className="mb-4 px-3">
            Delete Course Category
          </Modal.Title>
          <Modal.Body>
            <p>Are you sure you want to delete this Course Category?</p>
          </Modal.Body>

          <Modal.Footer className="m-auto border-0">
            <Button
              className="mb-5"
              variant="primary"
              size="lg"
              onClick={handleDeleteCat}
            >
              Yes
            </Button>
            <Button
              className="mb-5"
              variant="primary"
              size="lg"
              onClick={() => setShowDel(false)}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Course Category Model */}
      <Modal
        show={showCat}
        onHide={() => {
          setShowCat(false);
          setCatTitle();
          setCatTitle2();
          setImage();
          setImage2();
          setPrevImg();
          setPrevImg2();
        }}
        className="text-center meditation-model modal-content-80"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton className="border-0 p-0"></Modal.Header>
        <Modal.Title className="mb-4 px-3">Add New Category</Modal.Title>
        <Modal.Body>
          <Row>
            <Col lg={6}>
              <div className="category-label">
                <Form.Label>Add Category (English Text)</Form.Label>
                <Form.Control
                  onChange={(e) => setCatTitle(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Category name"
                  value={catTitle}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="category-label">
                <Form.Label>Add Category (German Text)</Form.Label>
                <Form.Control
                  onChange={(e) => setCatTitle2(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Kategoriename"
                  value={catTitle2}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <div className="category-label">
                <Form.Label>Select Category Color</Form.Label>
                <Form.Control
                  onChange={(e) => setcolor(e.target.value)}
                  size="lg"
                  type="color"
                  // placeholder="Category name"
                  value={color}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div className="category-label">
                <Form.Label>Add Description (English Text)</Form.Label>
                <Form.Control
                  onChange={(e) => setDescState(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Enter description"
                  value={descState}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="category-label">
                <Form.Label>Add Description (German Text)</Form.Label>
                <Form.Control
                  onChange={(e) => setDescState2(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Kategoriename"
                  value={descState2}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Form.Group className="mb-4 px-3" controlId="formGridAddress2">
          <div className="upld-place-click">
            <p>{image ? image?.name : "Click to upload image"}</p>
            <Form.Control
              className="add-upld-hide add-icon-hide"
              type="file"
              placeholder="click to upload audio file"
              onChange={handleImgUpload}
            />
          </div>
        </Form.Group>
        <div className="img-upld-place add-image-upld">
          {prevImg && <img src={prevImg} />}
        </div>

        {/* <Form.Group className="mb-4 px-3" controlId="formGridAddress2">
          <div className="upld-place-click">
            <p>
              {image2 ? image2?.name : "Klicken Sie, um das Bild hochzuladen"}
            </p>
            <Form.Control
              className="add-upld-hide add-icon-hide"
              type="file"
              placeholder="Klicken Sie, um das Bild hochzuladen"
              onChange={handleImgUpload2}
            />
          </div>
        </Form.Group> */}
        <div className="img-upld-place add-image-upld">
          {prevImg2 && <img src={prevImg2} />}
        </div>
        <Modal.Footer className="m-auto border-0">
          <Button
            className="mb-5"
            variant="primary"
            size="lg"
            onClick={handleCreateCategory}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* edit category */}
      <Modal
        show={showCatEdit}
        onHide={() => {
          setShowCat(false);
          setCatTitle();
          setCatTitle2();
          setImage();
          setImage2();
          setPrevImg();
          setPrevImg2();
          setshowCatEdit(false);
        }}
        className="text-center meditation-model modal-content-80"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton className="border-0 p-0"></Modal.Header>
        <Modal.Title className="mb-4 px-3">Edit Category</Modal.Title>
        <Modal.Body>
          <Row>
            <Col lg={6}>
              <div className="category-label">
                <Form.Label>Edit Category (English Text)</Form.Label>
                <Form.Control
                  onChange={(e) => setCatTitle(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Category name"
                  name="catTitle"
                  value={catTitle}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="category-label">
                <Form.Label>Edit Category (German Text)</Form.Label>
                <Form.Control
                  onChange={(e) => setCatTitle2(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Kategoriename"
                  value={catTitle2}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <div className="category-label">
                <Form.Label>Select Category Color</Form.Label>
                <Form.Control
                  onChange={(e) => setcolor(e.target.value)}
                  size="lg"
                  type="color"
                  // placeholder="Category name"
                  value={color}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div className="category-label">
                <Form.Label>Edit Description (English Text)</Form.Label>
                <Form.Control
                  onChange={(e) => setDescState(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Enter description"
                  value={descState}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="category-label">
                <Form.Label>Edit Description (German Text)</Form.Label>
                <Form.Control
                  onChange={(e) => setDescState2(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Kategoriename"
                  value={descState2}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Form.Group className="mb-4 px-3" controlId="formGridAddress2">
          <div className="upld-place-click">
            <p>
              {/* {image
                ? image?.name
                : catData?.category_img
                ? catData?.category_img
                : "Click to upload image"} */}
                Click to upload image
            </p>
            <Form.Control
              className="add-upld-hide add-icon-hide"
              type="file"
              placeholder="click to upload audio file"
              onChange={handleImgUpload}
            />
          </div>
        </Form.Group>
        <div className="img-upld-place add-image-upld">
          {prevImg && <img src={prevImg} />}
        </div>

        {/* <Form.Group className="mb-4 px-3" controlId="formGridAddress2">
          <div className="upld-place-click">
            <p>
              {image2 ? image2?.name : "Klicken Sie, um das Bild hochzuladen"}
            </p>
            <Form.Control
              className="add-upld-hide add-icon-hide"
              type="file"
              placeholder="Klicken Sie, um das Bild hochzuladen"
              onChange={handleImgUpload2}
            />
          </div>
        </Form.Group> */}
        <div className="img-upld-place add-image-upld">
          {prevImg2 && <img src={prevImg2} />}
        </div>
        <Modal.Footer className="m-auto border-0">
          <Button
            className="mb-5"
            variant="primary"
            size="lg"
            onClick={handleEditCategory}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
