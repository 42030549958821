import React, { useState } from "react";
import { Menu, MenuItem } from "react-pro-sidebar";
import {
  FiUsers,
  FiAlertOctagon,
  FiGrid,
  FiChevronLeft,
  FiChevronRight,
  FiUserCheck,
  FiFlag,
  FiBell,
  FiHelpCircle,
} from "react-icons/fi";
import logo_main from "../../images/upd-logo.png";
import Footer from "../Layout/Footer";
import { Link, useLocation } from "react-router-dom";

const Sidebar = ({ setCssclass }) => {
  const [menuCollapse, setMenuCollapse] = useState(false);

  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    setCssclass(menuCollapse ? "" : "wrap-content");
  };

  const { pathname } = useLocation();

  const isCurrentPage = (...tabs) => {
    return tabs.some((tab) => pathname.includes(tab));
    // return pathname.includes(tab) || pathname.includes(subpage);
  };

  const sidebarData = [
    {
      name: "Dashboard",
      to: "/admin/dashboard",
      active: isCurrentPage("dashboard"),
      icon: <FiGrid />,
    },
    {
      name: "User Management",
      to: "/admin/users",
      active: isCurrentPage("users", "userdetails"),
      icon: <FiUsers />,
    },
    {
      name: "Coaches",
      to: "/admin/coaches",
      active: isCurrentPage("coaches", "coachdetail"),
      icon: <FiUserCheck />,
    },
    {
      name: "Self Reflection",
      to: "/admin/category",
      active: isCurrentPage(
        "self-reflection",
        "add-new-reflection",
        "editSelfReflection"
      ),
      icon: <FiAlertOctagon />,
    },
    {
      name: "Meditation",
      to: "/admin/meditation",
      active: isCurrentPage("meditation"),
      icon: <FiFlag />,
    },
    {
      name: "Welcome Audio",
      to: "/admin/welcome-audio",
      active: isCurrentPage("welcome-audio"),
      icon: <FiFlag />,
    },
    {
      name: " Notification",
      to: "/admin/notification",
      active: isCurrentPage("notification"),
      icon: <FiBell />,
    },

    {
      name: " FAQs",
      to: "/admin/faqs",
      active: isCurrentPage("faqs", "editfaqs", "createfaqs"),
      icon: <FiBell />,
    },
    {
      name: " Imprint",
      to: "/admin/imprint",
      active: isCurrentPage("imprint"),
      icon: <FiBell />,
    },
    {
      name: "Help",
      to: "/admin/help",
      active: isCurrentPage("help", "edithelp", "createhelp"),
      icon: <FiHelpCircle />,
    },
  ];

  return (
    <>
      <div id="header" className={` vh-100 ${menuCollapse ? "collapsed" : ""}`}>
        <div className="pro-sidebar">
          <div className="SidebarHeader">
            <div className="logotext px-3 py-4">
              <img src={menuCollapse ? logo_main : logo_main} alt="logo"></img>
            </div>
            <div className="closemenu" onClick={menuIconClick}>
              {menuCollapse ? (
                <FiChevronRight className="side-icon" />
              ) : (
                <FiChevronLeft className="side-icon" />
              )}
            </div>
          </div>
          <div className="SidebarContent">
            <div className="text-muted mx-4 mb-3">MENU</div>
            <div className="bg-light mx-4 rounded-3 sidebar-scroll">
              <Menu iconShape="square">
                {sidebarData?.map((sidedata) => (
                  <Link to={sidedata?.to}>
                    <MenuItem active={sidedata?.active} icon={sidedata?.icon}>
                      {sidedata?.name}
                    </MenuItem>
                  </Link>
                ))}
              </Menu>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Sidebar;
