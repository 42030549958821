import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Card,
  Container,
  Row,
  Form,
  Button,
  Table,
  Stack,
  Modal,
  Col,
} from "react-bootstrap";
import { FiSearch, FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  addNotification,
  deleteNotification,
  getNotifications,
} from "../redux/actions/adminActions";
import { Formik } from "formik";

export default function Notification() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [delShow, setDelShow] = useState(false);
  const [delId, setDelId] = useState();
  const [search, setSearch] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const notifications = useSelector(
    (state) => state.notificationSlice.notifications
  );

  useEffect(() => {
    dispatch(search ? getNotifications(search) :getNotifications());
  }, [search]);

  const toObj = {
    0: "All",
    1: "All Users",
    2: "All Coaches",
  };

  const handleDelete = () => {
    dispatch(deleteNotification(delId));
    setDelShow(false);
  };

  return (
    <>
      <Layout>
        <Container fluid>
          <Row>
            <div className="d-flex justify-content-end mb-4 theme mt-5">
              <Button variant="primary" size="lg" onClick={handleShow}>
                <FiPlus /> Add
              </Button>
            </div>
            <Card className="border-0 p-4 rounded-card user-section notify-sec ">
              <Stack
                className="user-table-align"
                direction="horizontal"
                gap={3}
              >
                <div className="p-2">
                  <h3>Notification</h3>
                </div>
                <div className="p-2 ms-auto">
                  <div className="position-relative m-2 align-items-center">
                    <Form.Control
                      type="search"
                      placeholder="Search..."
                      className="serch-feild"
                      onChange={(e)=>setSearch(e.target.value)}
                    />
                    {!search && <FiSearch className="searchicon2" />}
                  </div>
                </div>
              </Stack>
              <Table
                responsive
                variant=""
                className="border-0 custom-table mt-3"
              >
                <thead className="first-td">
                  <tr>
                    <th>S.No</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>To</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {notifications?.map((not, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{not?.title}</td>
                      <td>{not?.desc}</td>
                      <td>{toObj[not?.to]}</td>
                      <td>
                        <Stack
                          direction="horizontal"
                          className="table-btn"
                          gap={3}
                        >
                          <Button
                            onClick={() => {
                              setDelShow(true);
                              setDelId(not?._id);
                            }}
                            variant="success"
                          >
                            Delete
                          </Button>
                        </Stack>
                      </td>
                    </tr>
                  ))}
                  {notifications?.length == 0 && (
                    <tr>
                      <td></td>
                      <td></td>
                      <td>
                        {" "}
                        <div className="no-data">
                          Content not available yet.
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </Row>
        </Container>
      </Layout>

      {/* Notify Model */}
      <Modal show={show} onHide={handleClose} className="notify-model">
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body className=" border-0">
          <Modal.Title className="text-center mb-5">
            Add Notification
          </Modal.Title>

          <Formik
            initialValues={{ title: "", desc: "", to: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.title) {
                errors.title = "Required*";
              }
              if (!values.desc) {
                errors.desc = "Required*";
              }
              if (!values.to) {
                errors.to = "Required*";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(addNotification(values));
              handleClose();
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit} className="notify-details">
                <Form.Select
                  aria-label="Default select example"
                  className="mb-3"
                  name="to"
                  onChange={handleChange}
                >
                  <option hidden={true}>Select User</option>
                  <option value={0}>All</option>
                  <option value={1}>All Users </option>
                  <option value={2}>All Coaches</option>
                </Form.Select>
                <span className="formik-errors">
                  {errors.to && touched.to && errors.to}
                </span>
                <Form.Group controlId="formGridEmail" className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    name="title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    type="text"
                    placeholder="Title"
                  />
                  <span className="formik-errors">
                    {errors.title && touched.title && errors.title}
                  </span>
                </Form.Group>
                <Form.Group className="mb-4" controlId="formGridAddress2">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    name="desc"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.desc}
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "100px" }}
                  />
                  <span className="formik-errors">
                    {errors.desc && touched.desc && errors.desc}
                  </span>
                </Form.Group>
                <Form.Group
                  className="mb-2 model-btn text-center notify-modal-btn"
                  controlId="formGridAddress2"
                >
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    size="lg"
                  >
                    Notify
                  </Button>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* Delete Modal */}

      {delId && (
        <Modal
          show={delShow}
          onHide={() => setDelShow(false)}
          className="notify-model"
        >
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <Modal.Title className="text-center mb-5">
            Delete Notification?
          </Modal.Title>
          <Modal.Body className=" border-0 text-center">
            <p>Are you sure you want to delete this notification?</p>
          </Modal.Body>
          <Modal.Footer className="m-auto border-0">
            <Button
              className="mb-5"
              variant="primary"
              size="lg"
              onClick={handleDelete}
            >
              Yes
            </Button>
            <Button
              className="mb-5"
              variant="primary"
              size="lg"
              onClick={() => setDelShow(false)}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
