import React, { useEffect, useState } from "react";
import { Container, Row, Card, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { FiArrowLeft, FiPlus } from "react-icons/fi";
import Layout from "../components/Layout/Layout";
import HTMLEditor from "../components/FAQs/HTMLEditor";
import { createImprint, getImprint } from "../redux/actions/adminActions";
import HTMLEditorEditAndCreate from "../components/FAQs/HTMLEditorEditAndCreate";

export default function Imprint() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [htmlContent, setHtmlContent] = useState();
    const [blockContent, setBlockContent] = useState();
    const [htmlContentGermen, setHtmlContentGermen] = useState();
    const [blockContentGermen, setBlockContentGermen] = useState();
    const [editorStatus, setEditorStatus] = useState(false);

    const handleSubmit = () => {
        if (!htmlContent) {
            toast.warning("Please provide all fields");
        } else {
            dispatch(
                createImprint({
                    html: htmlContent,
                    blockContent,
                    htmlGermen: htmlContentGermen,
                    blockContentGermen
                })
            )
                .then((response) => {
                    if (response.payload.success) {
                        toast.success(response.payload.message);
                    } else {
                        toast.warning(response.payload.message);
                    }
                })
                .catch((err) => toast.error(err.message));
        }
    };

    useEffect(() => {
        dispatch(getImprint()).then((item) => {
            setBlockContent(item?.payload?.data?.blockContent);
            setHtmlContent(item?.payload?.data?.html);
            setHtmlContentGermen(item?.payload?.data?.htmlGermen);
            setBlockContentGermen(item?.payload?.data?.blockContentGermen);
            setEditorStatus(true)
        });
    }, [dispatch]);

    console.log(htmlContent, "assignedTo");

    return (
        <>
            <Layout>
                <Container fluid>
                    <div className="backBtn">
                        <a onClick={() => navigate(-1)}>
                            <h5 className="text-primary-clr backpad cursor-pointer">
                                <FiArrowLeft /> Back
                            </h5>
                        </a>
                    </div>
                    <Row>
                        <Card className="rounded-card faqs">
                            <div className="top-flex justify-content-between">
                                <div>
                                    <h2>Add Imprint</h2>
                                </div>
                            </div>
                            <Container>

                                <div className="faq-label">
                                    <label>English Content</label>
                                    {
                                        blockContent ? <HTMLEditorEditAndCreate
                                            blockContent={blockContent}
                                            setHtmlContent={setHtmlContent}
                                            setBlockContent={setBlockContent}
                                        /> : editorStatus && <HTMLEditorEditAndCreate
                                            setHtmlContent={setHtmlContent}
                                            setBlockContent={setBlockContent}
                                        />

                                    }

                                </div>
                                <div className="faq-label">
                                    <label>Germen Content</label>
                                    {
                                        blockContentGermen ? <HTMLEditorEditAndCreate
                                        blockContent={blockContentGermen}
                                        setHtmlContent={setHtmlContentGermen}
                                        setBlockContent={setBlockContentGermen}
                                        /> : editorStatus && <HTMLEditorEditAndCreate
                                        setHtmlContent={setHtmlContentGermen}
                                            setBlockContent={setBlockContentGermen}
                                        />

                                    }

                                </div>
                                
                            </Container>

                            <Button onClick={handleSubmit} variant="primary" size="lg">
                                Create
                                <FiPlus />
                            </Button>
                        </Card>
                    </Row>
                </Container>
            </Layout>
        </>
    );
}
