import React from "react"
import Layout from "../components/Layout/Layout"
import { Card, Container, Row, Form, Button, Table, Stack, Dropdown, DropdownButton} from "react-bootstrap"
import {
    FiSearch
} from "react-icons/fi";


export default function Report(){
    return(
        <>
            <Layout>
                <Container fluid>
                    <Row>
                        <Card className="border-0 p-4 rounded-card user-section ">
                            <Stack direction="horizontal" gap={3}>
                                <div className="p-2"><h3>Report</h3></div>
                                <div className="p-2 ms-auto">
                                    <div className="position-relative m-2">
                                        <Form.Control type="search" placeholder="search.." className="serch-feild" />
                                        <FiSearch className="searchicon2" />
                                    </div>
                                </div>
                            </Stack>
                            <Table responsive variant="" className="border-0 custom-table mt-3" >
                                <thead className="first-td">
                                    <tr >
                                        <th>S.No</th>
                                        <th>Report by</th>
                                        <th>Report to</th>
                                        <th>Reason</th>
                                        <th>Description</th>
                                        <th>Status </th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>James</td>
                                        <td>Smith</td>
                                        <td>Lorem Ipsum is simply</td>
                                        <td>Lorem Ipsum is simply</td>
                                        <td>
                                        <DropdownButton id="dropdown-item-button " title="Temporary Banned" className="border-0 secondary-drop-area">
                                                <Dropdown.Item as="button">Active</Dropdown.Item>
                                                <Dropdown.ItemText>Temporary Banned</Dropdown.ItemText>
                                                <Dropdown.Item as="button">Permanent Banned</Dropdown.Item>
                                            </DropdownButton>
                                        </td>
                                        <td>
                                        <Stack direction="horizontal" className="table-btn" gap={3}>
                                            <Button as="a" variant="primary">
                                                Close the Action
                                            </Button>
                                            <Button as="a" variant="success">
                                                Delete
                                            </Button>
                                        </Stack>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>James</td>
                                        <td>Smith</td>
                                        <td>Lorem Ipsum is simply</td>
                                        <td>Lorem Ipsum is simply</td>
                                        <td>
                                        <DropdownButton id="dropdown-item-button " title="Temporary Banned" className="border-0 secondary-drop-area">
                                                <Dropdown.Item as="button">Active</Dropdown.Item>
                                                <Dropdown.ItemText>Temporary Banned</Dropdown.ItemText>
                                                <Dropdown.Item as="button">Permanent Banned</Dropdown.Item>
                                            </DropdownButton>
                                        </td>
                                        <td>
                                        <Stack direction="horizontal" className="table-btn" gap={3}>
                                            <Button as="a" variant="primary">
                                                Close the Action
                                            </Button>
                                            <Button as="a" variant="success">
                                                Delete
                                            </Button>
                                        </Stack>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card>
                    </Row>
                </Container>
            </Layout>
        </>
    )
}