import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getCoachDetails,
  getCoaches,
  verifyCoach,
} from "../actions/adminActions";

const initialState = {
  coaches: null,
  totalCoaches: null,
  coachDetails: null,
  availability: null,
};

export const coachSlice = createSlice({
  name: "coachSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCoaches.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.coaches = payload.data.coaches;
          state.totalCoaches = payload.data.totalCoaches;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(verifyCoach.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.coaches = state.coaches?.map((coach) =>
            coach?._id == payload?.data?._id ? payload?.data : coach
          );
          toast.success(payload?.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getCoachDetails.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.coachDetails = payload.data.coachDetails;
          state.availability = payload.data.availability;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      });
  },
});

export default coachSlice.reducer;
