// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA2xA5tG4BQVWyDUJmhsAzLRZ8Z5eMOlCg",
  authDomain: "mind-republic.firebaseapp.com",
  projectId: "mind-republic",
  storageBucket: "mind-republic.appspot.com",
  messagingSenderId: "12010412085",
  appId: "1:12010412085:web:266357479affac4063397c",
  measurementId: "G-YL7JHK783S",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BLFO5sxyYak23Daz-3UtpJ5qzjrqdSp9r-fhohyPJXje35qZGKbaJ1iiGu_Sn9L6IMpJ571rXJWYXZW_nla-iks",
  });
  // .then((currentToken) => {
  //   if (currentToken) {
  //     // console.log("current token for client: ", currentToken);
  //     return currentToken;
  //     // Perform any other neccessary action with the token
  //   } else {
  //     // Show permission request UI
  //     console.log(
  //       "No registration token available. Request permission to generate one."
  //     );
  //   }
  // })
  // .catch((err) => {
  //   console.log("An error occurred while retrieving token. ", err);
  // });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
