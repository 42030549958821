import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import { Row, Button, Col, Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";

const EditReflectionModal = ({
  show,
  setShow,
  givenQuestion,
  editQuestion,
}) => {
  const closeModal = () => {
    setShow(false);
  };

  const initialObj = {
    question: "",
    germen_question: "",
    type: 0,
    answers: [],
    germenAnswers: [],
  };

  useEffect(() => {
    if (givenQuestion) {
      setQuestion((prev) => {
        let tempObj = { ...givenQuestion };
        tempObj.answers = [...givenQuestion?.answers];
        tempObj.germenAnswers = [...givenQuestion?.germenAnswers];
        return tempObj;
      });
    }
  }, [givenQuestion]);

  const [question, setQuestion] = useState(initialObj);

  // type  0 for free text and 1 for mcqs
  //   {question:"", type: , answers:[{key:"", option:""}], correctans:""}

  const keyArr = ["A", "B", "C", "D"];
  const keyArr2 = ["A", "B", "C", "D"];

  const handleQuestion = (e, obkey) => {
    setQuestion((prev) => {
      let tempObj = { ...prev };
      tempObj[obkey] = e.target.value;
      return tempObj;
    });
  };

  const handleSubmit = () => {
    if (question.question == "")
      return toast.warning("Please provide a question");
    if (
      question.type == 1 &&
      question?.answers?.filter((ob) => ob?.option && ob?.option != "")
        ?.length < 4
    )
      return toast.warning("Please input every option for MCQs");

    if (question.type == 1 && !question?.correctans)
      return toast.warning("Please provide a correct answer");
    editQuestion(question);
    setQuestion(initialObj);
    closeModal();
  };

  // const handleOption = (e, key) => {
  //   setQuestion((prev) => {
  //     let tempObj = { ...prev };
  //     const op = tempObj?.answers?.find((ob) => ob.key == key);
  //     let updatedOption;
  //     if (op) {
  //       op.option = e.target.value;
  //       updatedOption = op;
  //     } else if (!op) {
  //       updatedOption = { key, option: e.target.value };
  //     }
  //     const answers = new Set([...tempObj?.answers, updatedOption]);
  //     tempObj.answers = [...answers];
  //     return tempObj;
  //   });
  // };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      className="text-center meditation-model modal-content-80"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      <Modal.Header closeButton className="border-0 p-0"></Modal.Header>
      <Modal.Title className="card-btn-add">Add New Questions</Modal.Title>
      <Modal.Body className="text-left">
        <Form className="course-details">
          <Form.Group className="mb-4" controlId="formGridEmail">
            <Form.Label>Question</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter question"
              onChange={(e) => handleQuestion(e, "question")}
              value={question?.question}
            />
            <Form.Label className="mt-4">Frage</Form.Label>
            <Form.Control
              type="text"
              placeholder="Geben Sie die Frage ein"
              onChange={(e) => handleQuestion(e, "germen_question")}
              value={question?.germen_question}
            />
          </Form.Group>
          {/* <div className="upld-audio-title">Answer Types</div> */}
          <div className="upld-audio-title">Answer Type</div>
          <Row>
            <Col md={6}>
              <div className="option-add-nre-refl">
                <div className="select-refl">
                  <input
                    type="radio"
                    id="test1"
                    className="form-check-input"
                    name="free-text"
                    onChange={() =>
                      setQuestion((prev) => {
                        let tempObj = { ...prev };
                        tempObj.type = 0;
                        return tempObj;
                      })
                    }
                    checked={question.type == 0}
                  />
                  Free Text
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="option-add-nre-refl">
                <div className="select-refl">
                  <input
                    type="radio"
                    id="test2"
                    className="form-check-input"
                    name="radio-group"
                    onChange={() =>
                      setQuestion((prev) => {
                        let tempObj = { ...prev };
                        tempObj.type = 1;
                        return tempObj;
                      })
                    }
                    checked={question.type == 1}
                  />
                  MCQ
                </div>
              </div>
            </Col>
          </Row>
          {question.type == 1 ? (
            <Form.Group className="mt-5">
              <div className="custom_check radio">
                <Row>
                  <Col md={6}>
                    <ul>
                      {keyArr?.map((key, i) => {
                        return (
                          <li key={i}>
                            <span>{key}</span>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput"
                              placeholder="Input here..."
                              value={question?.answers[i]?.option}
                              onChange={(e) =>
                                setQuestion((prev) => {
                                  let tempObj = { ...prev };
                                  tempObj.answers[i] = {
                                    key: key,
                                    option: e.target.value,
                                  };
                                  return tempObj;
                                })
                              }
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                  <Col lg={6}>
                    <ul>
                      {keyArr2?.map((key, i) => {
                        return (
                          <li key={i}>
                            <span>{key}</span>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput"
                              placeholder="Input here..."
                              value={question?.germenAnswers[i]?.option}
                              onChange={(e) =>
                                setQuestion((prev) => {
                                  let tempObj = { ...prev };
                                  tempObj.germenAnswers[i] = {
                                    key: key,
                                    option: e.target.value,
                                  };
                                  return tempObj;
                                })
                              }
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                </Row>
              </div>
              <div className="custom_check custom_check_hori radio mt-5">
                <div className="upld-audio-title mb-0">Answer</div>
                <ul>
                  {keyArr?.map((key, i) => (
                    <li key={i} className="list-item">
                      <input
                        type="radio"
                        className="hidden-box top0 cursor-pointer"
                        name="interestedIn"
                        value={key}
                        onChange={(e) => handleQuestion(e, "correctans")}
                      />
                      <label
                        className={`check--label ${
                          key == question.correctans && "checked-answer"
                        }`}
                      >
                        {key}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </Form.Group>
          ) : (
            <Form.Group className="mt-5">
              {/* <Form.Group className="mt-5 hide"> */}
              <Form.Control
                as="textarea"
                placeholder="Type here...."
                className="text-height-question"
                disabled
              />
            </Form.Group>
          )}
          <Form.Group
            className="mb-2 mt-3 model-btn text-center"
            controlId="formGridAddress2"
          >
            <Button
              // type="submit"
              variant="primary"
              size="lg"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditReflectionModal;
