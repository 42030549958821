import React, { useEffect } from "react";
import Layout from "../components/Layout/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUserPass,
  deactivateUser,
  deleteUser,
  editUserDetails,
  getUserDetails,
} from "../redux/actions/adminActions";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Field } from "formik";
import Select from "react-select";
import countriesWcode from "../components/constants/countriesWCode";
import countriesWDialCode from "../components/constants/countriesWDialCode";
import { toast } from "react-toastify";
import moment from "moment";
import { FiArrowLeft } from "react-icons/fi";

const UserDetails = () => {
  const countries = countriesWcode;
  const FILE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userMgmtSlice.userDetails);
  const [show, setShow] = useState(false);
  const [switchVal, setSwitch] = useState();
  const [delShow, setDelShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedCode, setSelectedCode] = useState();
  const [prevImg, setPrevImg] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserDetails(id));
  }, []);

  useEffect(() => {
    if (userDetails) {
      setSelectedCode({
        label: userDetails?.country_code,
        value: userDetails?.country_code,
      });

      setPrevImg(
        userDetails?.profile_image
          ? FILE_URL + userDetails?.profile_image
          : require("../images/dummy_img.png")
      );

      setSwitch(userDetails?.deactivated == 1 ? true : false);
    }
  }, [userDetails]);

  const themeMode = true;

  const customStyles = {
    // control: (provided, state) => ({
    //   ...provided,
    //   width: 68,

    // }),

    // dropdownIndicator: (provided) => ({
    //   ...provided,
    //   width: "15px",
    //   height: "20px",
    //   padding: -5,
    //   marginLeft: "50px",
    //   marginTop: -40,
    //   color: themeMode ? "#6e6e6e" : "white",
    // }),

    singleValue: (provided) => ({
      ...provided,
      color: themeMode ? "#6e6e6e" : "white",
    }),

    control: (provided, state) => ({
      ...provided,
      width: 90,
      // backgroundColor: themeMode ? "white" : "#1e1e1e",
      backgroundColor: "#efefef",
      border: `1px solid ${themeMode ? "#cccccc" : "#444444"}`,
      height: "48px",
      borderRadius: "4px",
      boxShadow: state.isFocused ? "1px solid #b79c6c" : "none",
      "&:hover": {
        border: "1px solid #b79c6c",
      },
      // borderColor: state.isFocused ? "#ab91ed" : "#282828",
    }),
    input: (provided) => ({
      ...provided,
      color: themeMode ? "#6e6e6e" : "white",
      // margin: "0px",
      // padding: "8px",
    }),
    menu: (provided) => ({
      ...provided,
      width: 200, // Set the width for the dropdown menu
    }),
    indicatorSeparator: () => ({ display: "none" }),
    dropdownIndicator: (provided) => ({
      // ...provided,
      // with: "1px",
      ...provided,
      width: "15px",
      height: "20px",
      padding: -5,
      // marginRight: "5px",
      // marginTop: -43,
    }),
    clearIndicator: (provided) => ({
      // ...provided,
      display: "none",
    }),
  };

  const handleDeactivate = () => {
    const ob = {
      userId: id,
      deactivatedStatus: userDetails?.deactivated == 0 ? 1 : 0,
    };
    dispatch(deactivateUser(ob));
    dispatch(getUserDetails(id));
    setSwitch((prev) => !prev);
    handleClose();
  };

  const handleAccDel = () => {
    dispatch(deleteUser(id));
    navigate("/admin/users");
    setDelShow(false);
  };

  console.log(userDetails,"userdeatsil")

  return (
    <Layout>
      <Container fluid>
        <div className="backBtn">
          <a onClick={() => navigate(-1)}>
            <h5 className="text-primary-clr backpad cursor-pointer">
              <FiArrowLeft /> Back
            </h5>
          </a>
        </div>
        <div className="card_main">
          <Row>
            <Formik
              enableReinitialize
              initialValues={{
                email: userDetails?.email,
                full_name: userDetails?.full_name,
                phone_number: userDetails?.phone_number,
                suspended: userDetails?.deactivated,
                image: null,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = "Required*";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }
                if (!values.full_name) {
                  errors.full_name = "Required*";
                }
                if (!values?.phone_number) {
                  errors.full_name = "Required*";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                if (!selectedCode)
                  return toast.warning("Please select a country code");

                const formdata = new FormData();
                formdata.append("userId", id);
                formdata.append("full_name", values?.full_name);
                formdata.append("email", values?.email);
                formdata.append("phone_number", values?.phone_number);
                values?.suspended &&
                  formdata.append("suspended", values?.suspended);
                formdata.append("country_code", selectedCode.value);
                values?.image &&
                  formdata.append("profile_image", values?.image);

                dispatch(editUserDetails(formdata));
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={2}>
                      <div className="input_file_f">
                        <img src={prevImg} alt="Profile" />

                        <div className="upload_btn">
                          <p>Upload</p>
                          <input
                            className="form-control"
                            type="file"
                            id="formFile"
                            
                            onChange={(event) => {
                              if (event.currentTarget.files[0]) {
                                setFieldValue(
                                  "image",
                                  event.currentTarget.files[0]
                                );
                                setPrevImg(
                                  URL.createObjectURL(
                                    event.currentTarget.files[0]
                                  )
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className="inputs_main">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Full Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="full_name"
                            placeholder="Full name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.full_name}
                          />
                          <span className="formik-errors">
                            {errors?.full_name &&
                              touched?.full_name &&
                              errors?.full_name}
                          </span>
                        </Form.Group>
                        <Form.Group
                          className="mb-3 phone_input"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Phone Number</Form.Label>
                          <div className="d-flex">
                            <Select
                              className="phone_select"
                              value={
                                selectedCode
                                  ? {
                                      label: selectedCode.value,
                                      value: selectedCode.value,
                                    }
                                  : null
                              }
                              onChange={(e) => setSelectedCode(e)}
                              options={countriesWDialCode}
                              isClearable={true}
                              placeholder="+00"
                              styles={customStyles}
                            />
                            <Form.Control
                              type="number"
                              placeholder="Phone Number"
                              name="phone_number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.phone_number}
                            />
                          </div>
                          <span className="formik-errors">
                            {errors?.phone_number &&
                              touched?.phone_number &&
                              errors?.phone_number}
                          </span>
                        </Form.Group>

                        <div className="sub_btn">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className="inputs_main">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Email address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.email}
                          />
                          <span className="formik-errors">
                            {errors?.email && touched?.email && errors?.email}
                          </span>
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Status</Form.Label>

                          <Form.Select
                            
                            id="status"
                            name="suspended"
                            value={values.suspended}
                            onChange={handleChange}
                            aria-label="Default select example"
                          >
                            <option value="0">Active</option>
                            <option value="1">Suspended</option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                </form>
              )}
            </Formik>
          </Row>
        </div>

        <Row>
          <Col lg={6}>
            <div className="card_small">
              <div className="head">
                <h2>Other Information</h2>
              </div>
              <div className="icons_list">
                <ul>
                  <li>
                    <svg
                      className=""
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="AirplayIcon"
                    >
                      <path d="M6 22h12l-6-6z"></path>
                      <path d="M21 3H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h4v-2H3V5h18v12h-4v2h4c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
                    </svg>
                    <div className="icons_right">
                      <p>Last Login</p>
                      <h6>
                        {moment(userDetails?.lastLogin).format("Do MMMM YYYY")}
                      </h6>
                    </div>
                  </li>
                  <li>
                    <svg
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="SpaceDashboardIcon"
                    >
                      <path d="M11 21H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h6v18zm2 0h6c1.1 0 2-.9 2-2v-7h-8v9zm8-11V5c0-1.1-.9-2-2-2h-6v7h8z"></path>
                    </svg>

                    <div className="icons_right">
                      <p>Login type</p>
                      <h6>General</h6>
                    </div>
                  </li>
                  <li>
                    <svg
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="CalendarMonthIcon"
                    >
                      <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM9 14H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm-8 4H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z"></path>
                    </svg>

                    <div className="icons_right">
                      <p>Registered Date</p>
                      <h6>
                        {moment(userDetails?.createdAt).format("Do MMMM YYYY")}
                      </h6>
                    </div>
                  </li>
                  <li>
                    <svg
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="PhoneAndroidIcon"
                    >
                      <path d="M16 1H8C6.34 1 5 2.34 5 4v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V4c0-1.66-1.34-3-3-3zm-2 20h-4v-1h4v1zm3.25-3H6.75V4h10.5v14z"></path>
                    </svg>
                    <div className="icons_right">
                      <p>Device Type</p>
                      <h6>
                        {userDetails?.device_type == 0 ? "iOS" : "Android"}
                      </h6>
                    </div>
                  </li>
                  <li>
                    <svg
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="ReportGmailerrorredIcon"
                    >
                      <path d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM19 14.9 14.9 19H9.1L5 14.9V9.1L9.1 5h5.8L19 9.1v5.8z"></path>
                      <path d="M11 7h2v6h-2zm0 8h2v2h-2z"></path>
                    </svg>
                    <div className="icons_right">
                      <p>Device Model</p>
                      <h6>{userDetails?.device_model}</h6>
                    </div>
                  </li>
                  <li>
                    <svg
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="LocationOnIcon"
                    >
                      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path>
                    </svg>
                    <div className="icons_right">
                      <p>Country</p>
                      <h6>
                        {userDetails?.country_code
                          ? (userDetails?.country_code,
                            countries[userDetails?.country_code])
                          : "N/A"}
                      </h6>
                    </div>
                  </li>
                  <li>
                    <svg
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="PermIdentityIcon"
                    >
                      <path d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"></path>
                    </svg>
                    <div className="icons_right">
                      <p>Gender</p>
                      <h6>
                        {userDetails?.gender != undefined
                          ? userDetails?.gender == 0
                            ? "Female"
                            : userDetails?.gender == 1
                            ? "Male"
                            : userDetails?.gender == 2 && "Prefer not to say"
                          : "N/A"}
                      </h6>
                    </div>
                  </li>
                  <li>
                    <svg
                      className=""
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="AirplayIcon"
                    >
                      <path d="M6 22h12l-6-6z"></path>
                      <path d="M21 3H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h4v-2H3V5h18v12h-4v2h4c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
                    </svg>
                    <div className="icons_right">
                      <p>Language</p>
                      <h6>{userDetails?.language}</h6>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Col>

          <Col lg={6}>
            <div className="card_small">
              <div className="head">
                <h2>Social</h2>
              </div>
              <div className="social">
                <ul>
                  <li>
                    <svg
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="GoogleIcon"
                    >
                      <path d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z"></path>
                    </svg>
                    <p>N/A</p>
                  </li>
                  <li>
                    <svg
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="AppleIcon"
                    >
                      <path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11z"></path>
                    </svg>
                    <p>N/A</p>
                  </li>
                  <li>
                    <svg
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="FacebookIcon"
                    >
                      <path d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z"></path>
                    </svg>
                    <p>N/A</p>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <Row className="set_height">
              <Col lg={6}>
                <div className="card_small">
                  <div className="head">
                    <h2>De-Activate Account</h2>
                  </div>
                  <div className="toggle">
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                      label="You will not be able to receive messages, notifications for up to 24 hours."
                      onClick={handleShow}
                      checked={switchVal}
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="card_small">
                  <div className="head">
                    <h2>Delete Account</h2>
                  </div>
                  <div className="dlt_acount">
                    <h6>
                      Once you delete the account, there is no going back.
                      Please be certain.
                    </h6>
                    <button
                      onClick={() => setDelShow(true)}
                      className="btn btn-danger "
                    >
                      Delete my account
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col lg={6}>
            <div className="card_small">
              <div className="head">
                <h2>Change Password</h2>
              </div>
              <Row className="pt-4">
                <Formik
                  initialValues={{
                    password: "",
                    confirmPassword: "",
                    userId: id,
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values?.password) {
                      errors.password = "Required*";
                    }
                    if (!values?.confirmPassword) {
                      errors.confirmPassword = "Required*";
                    }
                    if (values?.password != values?.confirmPassword) {
                      errors.confirmPassword = "Passwords doesn't match";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    dispatch(changeUserPass(values));
                    resetForm();
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Password"
                              name="password"
                              onChange={handleChange}
                              value={values?.password}
                            />
                            <span className="formik-errors">
                              {errors?.password &&
                                touched?.password &&
                                errors?.password}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Confirm Password"
                              name="confirmPassword"
                              onChange={handleChange}
                              value={values?.confirmPassword}
                            />
                            <span className="formik-errors">
                              {errors?.confirmPassword &&
                                touched?.confirmPassword &&
                                errors?.confirmPassword}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <div className="sub_btn">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary"
                            >
                              Submit
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  )}
                </Formik>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Body>
          <p className="modal_head">
            Are you sure you want to Activate/Deactivate this account?
          </p>
        </Modal.Body>
        <Modal.Footer className="m-auto">
          <Button variant="primary" onClick={handleDeactivate}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={delShow} onHide={() => setDelShow(false)} animation={false}>
        <Modal.Body>
          <p className="modal_head">
            Are you sure you want to Delete this account?
          </p>
        </Modal.Body>
        <Modal.Footer className="m-auto">
          <Button variant="primary" onClick={handleAccDel}>
            Yes
          </Button>
          <Button variant="primary" onClick={() => setDelShow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default UserDetails;
