import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  changeReportUserStatus,
  deleteReport,
  getReports,
} from "../actions/adminActions";

const initialState = {
  reports: null,
};

export const reportsSlice = createSlice({
  name: "reportsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReports.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.reports = payload.data;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deleteReport.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.reports = state.reports?.filter(
            (report) => report?._id != payload.data?._id
          );
          toast.success(payload?.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      });
  },
});

export default reportsSlice.reducer;
