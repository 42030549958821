import { Row, Image } from "react-bootstrap";
import { FiUnlock } from "react-icons/fi";
import Dropdown from "react-bootstrap/Dropdown";
import Profile from "../../images/Photo_profile.svg";
import { FiLogOut } from "react-icons/fi";
import Stack from "react-bootstrap/Stack";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "universal-cookie";

function Header() {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.removeItem("token");
    cookies.remove("token", { path: "/" });
    navigate("/");
  };
  return (
    <>
      <div className="top-header" fluid="xl">
        <Row className="pt-4">
          <Stack direction="horizontal" gap={3}>
            <div className="ms-auto">
              <Dropdown>
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  <Image className="class-place" src={Profile} />
                </Dropdown.Toggle>

                <Dropdown.Menu className="menuarea pt-3 shadow">
                  <Dropdown.Item eventKey="4" className="text-primary-clr">
                    Mind Republic
                  </Dropdown.Item>
                  <Dropdown.ItemText>Admin</Dropdown.ItemText>
                  <Dropdown.Divider />
                  <Dropdown.Item>
                    <Link to="/admin/change-password">
                      <FiUnlock /> Change Password
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout}>
                    <FiLogOut /> Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Stack>
        </Row>
      </div>
    </>
  );
}

export default Header;
