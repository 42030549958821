import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const HTMLEditor = ({ blockContent, setHtmlContent, setBlockContent }) => {
  const [description, setDescription] = useState({
    htmlValue: "<p></p>\n",
    editorState: blockContent
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(blockContent)))
      : EditorState.createEmpty(),
    // editorState: EditorState.createEmpty(),
  });

  const onEditorStateChange = (editorValue) => {
    const editorStateInHtml = draftToHtml(
      convertToRaw(editorValue.getCurrentContent())
    );

    setDescription({
      htmlValue: editorStateInHtml,
      editorState: editorValue,
    });

    setHtmlContent(editorStateInHtml);

    setBlockContent(
      JSON.stringify(convertToRaw(editorValue.getCurrentContent()))
    );
  };
  return (
    <div>
      <Editor
        toolbarHidden={false}
        editorState={description.editorState}
        onEditorStateChange={onEditorStateChange}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        placeholder="Type Here...."
      />
    </div>
  );
};

export default HTMLEditor;
