import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Container,
  Row,
  Card,
  ListGroup,
  Image,
  Button,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Deleteicon from "../images/delete_icon.svg";
import Editicon from "../images/edit_icon.svg";
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  createAudioFile,
  deleteReflection,
  getAudioFile,
  getReflections,
} from "../redux/actions/adminActions";
import { toast } from "react-toastify";

export default function WelcomeAudio() {
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [stateDetail, setStateDetail] = useState({
    audio: "",
    germen_audio: "",
    title: "",
    germen_title: "",
    image:"",
    color:""
  });
  const [prevImg, setPrevImg] = useState();
  // const [image, setImage] = useState("");
  // const [color, setColor] = useState("");
  
  // useEffect(()=>{
  //   setPrevImg(URL.createObjectURL(stateDetail?.image))

  // },[stateDetail?.image])

  // const handleImgUpload = (e) => {
  //   setPrevImg(URL.createObjectURL(e.target.files[0]));
  //   setImage(e.target.files[0]);
  // };

  const audioFile = useSelector((state) => state.meditationSlice.audioFile);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAudioFile()).then((data) => {
      console.log(data?.payload?.data, "sdhgfsdygfy");
      setStateDetail(data?.payload?.data);
      

    });
  }, []);

  const handleChange = (e) => {
    let { name, value, files } = e.target;

    if (name == "audio" || name == "germen_audio") {
      setStateDetail({ ...stateDetail, [name]: files[0] });
    } else if(name == "image"){
      setStateDetail({ ...stateDetail, image: files[0] })
      setPrevImg(URL.createObjectURL(files[0]));
    }else {
      setStateDetail({ ...stateDetail, [name]: value });
    }

    
  };

  console.log(stateDetail,"state")

  const submitForm = () => {
    const formData = new FormData();

    for (const key in stateDetail) {
      if (Object.hasOwnProperty.call(stateDetail, key)) {
        const value = stateDetail[key];
        formData.append(key, value);
      }
    }


  

   


    dispatch(createAudioFile(formData))
      .then((response) => {
        if (response.payload.success) {
          toast.success(response.payload.message);
        } else {
          toast.warning(response.payload.message);
        }
      })
      .catch((err) => toast.error(err.message));
  };
  console.log(audioFile, "key");
  console.log(prevImg)

  return (
    <>
      <Layout>
        <Container fluid>
          <Row>
            <Card className="border-0 card-user-detail rounded-card meditation-info px-4 mt-4 mb-5">
              <div className="top-flex justify-content-between mb-5">
                <div>
                  <h2>Welcome Audio</h2>
                </div>
              </div>
              <Container>
                <Row>
                  <Col lg={6}>
                    <Form.Label>
                      Reflection Journey Title (English Text)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Reflection Journey Title"
                      name="title"
                      onChange={handleChange}
                      value={stateDetail?.title}
                    />
                  </Col>
                  <Col lg={6}>
                    <Form.Label>
                      Titel der Reflexionsreise (German Text)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Titel der Reflexionsreise"
                      name="germen_title"
                      onChange={handleChange}
                      value={stateDetail?.germen_title}
                    />
                  </Col>
                </Row>
                <Row>
                 
                  <Col lg={6}>
                    <Form.Label className="mt-3">
                     Select Color Title 
                    </Form.Label>
                    <Form.Control
                      type="color"
                      // placeholder="Titel der Reflexionsreise"
                      name="color"
                      onChange={handleChange}
                      value={stateDetail?.color}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col lg={6}>
                    <div className="upld-audio-title">
                      Upload Audio file (English File)
                    </div>
                    <div className="upld-place-click">
                      <p>{stateDetail?.audio?.name ? stateDetail?.audio?.name : stateDetail?.audio ? stateDetail?.audio : "Click to upload audio file" }</p>
                      <Form.Control
                        className="add-upld-hide upld-audio-hide"
                        type="file"
                        name="audio"
                        placeholder="click to upload audio file"
                        onChange={handleChange}
                        // value={stateDetail?.audio}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="upld-audio-title">
                      Audiodatei hochladen (German File)
                    </div>
                    <div className="upld-place-click">
                      <p>{stateDetail?.germen_audio?.name ? stateDetail?.germen_audio?.name : stateDetail?.germen_audio ? stateDetail?.germen_audio :"Klicken Sie hier, um die Audiodatei hochzuladen"}</p>
                      <Form.Control
                        className="add-upld-hide upld-audio-hide"
                        type="file"
                        name="germen_audio"
                        placeholder="Klicken Sie hier, um die Audiodatei hochzuladen"
                        onChange={handleChange}
                        // value={stateDetail?.germen_audio}
                      />
                    </div>
                  </Col>

                  <Form.Group
                    className="mb-4 px-3 mt-5"
                    controlId="formGridAddress2"
                  >
                    <div className="upld-place-click">
                      <p>{stateDetail?.image?.name ? stateDetail?.image?.name : stateDetail?.image ? stateDetail?.image : "Click to upload image"}</p>
                      <Form.Control
                        className="add-upld-hide add-icon-hide"
                        type="file"
                        placeholder="click to upload audio file"
                        onChange={handleChange}
                        name="image"
                      />
                    </div>
                  </Form.Group>
                  <div className="img-upld-place add-image-upld">
                    {(prevImg || stateDetail?.image) && <img src={prevImg == undefined ? `${BASE_URL}/${stateDetail?.image}` : prevImg } />}
                  </div>
                  <Col lg={12} className="d-flex justify-content-center mt-4">
                    <Button onClick={submitForm} variant="primary" size="lg">
                      Add
                      <FiPlus />
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Card>
          </Row>
        </Container>
      </Layout>
    </>
  );
}