import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Container,
  Row,
  Card,
  ListGroup,
  Image,
  Button,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import { FiPlus } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import Deleteicon from "../images/delete_icon.svg";
import Editicon from "../images/edit_icon.svg";
import Playicon from "../images/play_icon.svg";
import playpop from "../images/plypop.png";
import { useDispatch, useSelector } from "react-redux";
import {
  EditCategorybyid,
  EditCourseCategorybyid,
  createCourse,
  createCourseCategory,
  deleteCourseCategory,
  getCourseCategories,
  getMeditationbyid,
} from "../redux/actions/adminActions";
import { toast } from "react-toastify";

export default function Meditation() {
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [showCat, setShowCat] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [delId, setDelId] = useState();
  const [courseCatId, setCourseCatId] = useState();
  const [showCourse, setShowCourse] = useState(false);
  const [courseTitle, setCourseTitle] = useState("");
  const [germanyTitle, setgermanyTitle] = useState("");
  const [audio, setAudio] = useState();
  const [germanaudio, setgermanAudio] = useState();
  const [image, setImage] = useState();
  const [prevImg, setPrevImg] = useState();
  const [image2, setImage2] = useState();
  const [prevImg2, setPrevImg2] = useState();
  const [catTitle, setCatTitle] = useState("");
  const [catTitle2, setCatTitle2] = useState("");
  const [catDescTitle, setCatDescTitle] = useState("");
  const [catDescTitle2, setCatDescTitle2] = useState("");
  const [editCourseCategory, seteditCourseCategory] = useState(false);
  const [editId, setEditId] = useState("");

  const courseCats = useSelector((state) => state.meditationSlice.courseCats);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCourseCategories());
  }, []);

  const handleDeleteCat = () => {
    dispatch(deleteCourseCategory(delId)).then(() => setShowDel(false));
  };

  const handleImgUpload = (e) => {
    setPrevImg(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  // const handleImgUpload2 = (e) => {
  //   setPrevImg2(URL.createObjectURL(e.target.files[0]));
  //   setImage2(e.target.files[0]);
  // };

  useEffect(() => {
    console.log("edit")
    const fetchdata=async()=>{
      try {
        let data =await dispatch(getMeditationbyid(editId));
        
        if(data?.payload?.success){
          console.log(data)
          
          setCatTitle(data?.payload?.data?.title)
          setCatTitle2(data?.payload?.data?.germen_title)
          // setcolor(data?.payload?.data?.color)
          setCatDescTitle(data?.payload?.data?.desc)
          setCatDescTitle2(data?.payload?.data?.germen_desc)
          setImage(data?.payload?.data?.image)
          setPrevImg(`https://mindrepublic-api.bosselt.com${data?.payload?.data?.image}`)
        }
      } catch (error) {
        
      }

    }
    fetchdata()
    
    
  }, [editCourseCategory]);

  const handleCreateCategory = () => {
    if (catTitle == "" || catTitle2 == "") {
      toast.warning("Category Title is required");
      return;
    }
    if (catTitle != "" && catTitle2 != "" && !image) {
      toast.warning("Image File is required");
      return;
    }

    

    const formData = new FormData();

    formData.append("image", image);
    formData.append("germen_image", image2);
    formData.append("title", catTitle);
    formData.append("germen_title", catTitle2);
    formData.append("desc", catDescTitle);
    formData.append("germen_desc", catDescTitle2);

    dispatch(createCourseCategory(formData));
    setShowCat(false);
    setCatTitle();
    setCatTitle2();
    setCatDescTitle();
    setCatDescTitle2();
    setImage();
    setPrevImg();
  };

  const handleEditCategory=()=>{
    if (catTitle == "" || catTitle2 == "") {
      toast.warning("Category Title is required");
      return;
    }
    if (catTitle != "" && catTitle2 != "" && !image) {
      toast.warning("Image File is required");
      return;
    }

    const formData = new FormData();

    formData.append("image", image);
    // formData.append("germen_image", image2);
    formData.append("title", catTitle);
    formData.append("germen_title", catTitle2);
    formData.append("desc", catDescTitle);
    formData.append("germen_desc", catDescTitle2);
    
    formData.append("id", editId);

    dispatch(EditCourseCategorybyid(formData));
    seteditCourseCategory(false);
    setCatTitle();
    setCatTitle2();
    setImage();
    setPrevImg();
    setCatDescTitle();
    setCatDescTitle2();
    dispatch(getCourseCategories());
    

  }


  
  const handleCreateCourse = () => {
    if (courseTitle == "") {
      toast.warning("Course Title is required");
      return;
    }
    if (courseTitle != "" && !audio) {
      toast.warning("Audio File is required");
      return;
    }
    const formData = new FormData();

    formData.append("title", courseTitle);
    formData.append("germanyTitle",germanyTitle)
    // formData.append("desc", catDescTitle);
    // formData.append("germen_desc", catDescTitle2);
    formData.append("audio", audio);
    formData.append("germen_audio", germanaudio);
    formData.append("course_category_id", courseCatId);


    dispatch(createCourse(formData));
    setShowCourse(false);
    setCourseTitle();
    setAudio();
    setgermanyTitle()
    setgermanAudio()
  };

  console.log(image,"image")

  return (
    <>
      <Layout>
        <Container fluid>
          <Row>
            <Card className="border-0 card-user-detail rounded-card meditation-info px-4 mt-4">
              <div className="top-flex justify-content-between">
                <div>
                  <h2>Meditation</h2>
                </div>
                <div>
                  <Button
                    className="add-category-btn"
                    variant="primary"
                    onClick={() => setShowCat(true)}
                  >
                    <FiPlus /> Add New Category
                  </Button>
                </div>
              </div>
              <h5 className="py-2 mt-3">Courses in Meditation</h5>
              <Row className="mt-4">
                {courseCats?.length > 0 &&
                  courseCats?.map((cat) => {
                    return (
                      <Col
                        key={cat?._id}
                        xxl={3}
                        xl={4}
                        lg={4}
                        md={6}
                        className="table-card mb-5 "
                      >
                        <Card className="text-center card-meditation">
                          <Link to={`/admin/courses/${cat?._id}/${cat?.title}`}>
                            <Card.Header className="cursor-pointer">
                              <img src={BASE_URL + cat?.image} />
                            </Card.Header>
                          </Link>

                          <Card.Body className="card-theme-med pb-0">
                            <Card.Title>{cat?.title}</Card.Title>
                            <Card.Text>{cat?.course_count} courses</Card.Text>
                            <Button
                              variant="primary"
                              onClick={() => {
                                setShowCourse(true);
                                setCourseCatId(cat?._id);
                              }}
                              className="card-btn-add"
                            >
                              Add New Course
                            </Button>
                            <div
                              onClick={() => {
                                setShowDel(true);
                                setDelId(cat?._id);
                              }}
                              className="card-delete"
                            >
                              <Link>Delete</Link>
                            </div>
                            <div
                              onClick={() => {
                                seteditCourseCategory(true);
                                setEditId(cat?._id);
                              }}
                              className="card-delete"
                            >
                              <Link>Edit</Link>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </Card>
          </Row>
        </Container>
      </Layout>

      {/* Delete Modal */}

      {delId && (
        <Modal
          show={showDel}
          onHide={() => setShowDel(false)}
          className="text-center meditation-model modal-content-80"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton className="border-0 p-0"></Modal.Header>
          <Modal.Title className="mb-4 px-3">
            Delete Course Category
          </Modal.Title>
          <Modal.Body>
            <p>Are you sure you want to delete this Course Category?</p>
          </Modal.Body>

          <Modal.Footer className="m-auto border-0">
            <Button
              className="mb-5"
              variant="primary"
              size="lg"
              onClick={handleDeleteCat}
            >
              Yes
            </Button>
            <Button
              className="mb-5"
              variant="primary"
              size="lg"
              onClick={() => setShowDel(false)}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Course Category Model */}
      <Modal
        show={showCat}
        onHide={() => {
          setShowCat(false);
          setCatTitle();
          setCatTitle2();
          setCatDescTitle();
          setCatDescTitle2();
          setImage();
          setImage2();
          setPrevImg();
          setPrevImg2();
        }}
        className="text-center meditation-model modal-content-80"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton className="border-0 p-0"></Modal.Header>
        <Modal.Title className="mb-4 px-3">Add New Category</Modal.Title>
        <Modal.Body>
          <Row>
            <Col lg={6}>
              <div className="category-label">
                <Form.Label>Add Category (English Text)</Form.Label>
                <Form.Control
                  onChange={(e) => setCatTitle(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Category name"
                  value={catTitle}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="category-label">
                <Form.Label>Add Category (German Text)</Form.Label>
                <Form.Control
                  onChange={(e) => setCatTitle2(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Kategoriename"
                  value={catTitle2}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div className="category-label">
                <Form.Label>Add Description (English Text)</Form.Label>
                <Form.Control
                  onChange={(e) => setCatDescTitle(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Category name"
                  value={catDescTitle}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="category-label">
                <Form.Label>Add Description (German Text)</Form.Label>
                <Form.Control
                  onChange={(e) => setCatDescTitle2(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Kategoriename"
                  value={catDescTitle2}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Form.Group className="mb-4 px-3" controlId="formGridAddress2">
          <div className="upld-place-click">
            <p>{image ? image?.name : "Click to upload image"}</p>
            <Form.Control
              className="add-upld-hide add-icon-hide"
              type="file"
              placeholder="click to upload audio file"
              onChange={handleImgUpload}
            />
          </div>
        </Form.Group>
        <div className="img-upld-place add-image-upld">
          {prevImg && <img src={prevImg} />}
        </div>

        {/* <Form.Group className="mb-4 px-3" controlId="formGridAddress2">
          <div className="upld-place-click">
            <p>
              {image2 ? image2?.name : "Klicken Sie, um das Bild hochzuladen"}
            </p>
            <Form.Control
              className="add-upld-hide add-icon-hide"
              type="file"
              placeholder="Klicken Sie, um das Bild hochzuladen"
              onChange={handleImgUpload2}
            />
          </div>
        </Form.Group> */}
        <div className="img-upld-place add-image-upld">
          {prevImg2 && <img src={prevImg2} />}
        </div>
        <Modal.Footer className="m-auto border-0">
          <Button
            className="mb-5"
            variant="primary"
            size="lg"
            onClick={handleCreateCategory}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {courseCatId && (
        <Modal
          show={showCourse}
          onHide={() => {
            setShowCourse(false);
            setCourseTitle();
            setAudio();
            setgermanAudio()
          }}
          className="text-center meditation-model modal-content-80"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton className="border-0 p-0"></Modal.Header>
          <Modal.Title className="card-btn-add">Add New Course</Modal.Title>
          <Modal.Body className="text-left">
            <Form className="course-details">
              {/* <Form.Label>Select Category</Form.Label>
            <Form.Select className="mb-4" aria-label="Default select example">
              <option>Select User</option>
              <option value="1">Male </option>
              <option value="2">Female</option>
              <option value="3">All Other</option>
            </Form.Select> */}

              <Form.Group className="mb-4" controlId="formGridEmail">
                <Form.Label>Course Title (English)</Form.Label>
                <Form.Control
                  onChange={(e) => setCourseTitle(e.target.value)}
                  type="text"
                  placeholder="Title"
                  value={courseTitle}
                />
                <Form.Label>Course Title (German)</Form.Label>
                <Form.Control
                  onChange={(e) => setgermanyTitle(e.target.value)}
                  type="text"
                  placeholder="Titel"
                  value={germanyTitle}
                />
              </Form.Group>

              <Form.Group className="mb-4" controlId="formGridAddress2">
                <div className="upld-place-click">
                  <p>Click to upload english audio file</p>
                  <Form.Control
                    className="add-upld-hide"
                    type="file"
                    placeholder="click to upload audio file"
                    onChange={(e) => {
                      setAudio(e.target.files[0]);
                      e.target.value = null;
                    }}
                  />
                </div>
                {audio && (
                  <div className="audio-place-holder mt-3">
                    <ListGroup as="ol" numbered className="theory-list">
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between list-card-play m-0"
                      >
                        <div className="ms-2 me-auto d-flex align-items-center">
                          <div className="icons-list">
                            <Image
                              src={Playicon}
                              className="custom-cursor-pointer img-play-card"
                            />
                          </div>
                          <div className="list-title">{audio?.name}</div>
                        </div>

                        <div className="icons-list">
                          <Image
                            onClick={() => setAudio()}
                            className="custom-cursor-pointer"
                            src={Deleteicon}
                          />
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                )}
              </Form.Group>


              <Form.Group className="mb-4" controlId="formGridAddress2">
                <div className="upld-place-click">
                  <p>Click to upload german audio file</p>
                  <Form.Control
                    className="add-upld-hide"
                    type="file"
                    placeholder="click to upload audio file"
                    onChange={(e) => {
                      setgermanAudio(e.target.files[0]);
                      e.target.value = null;
                    }}
                  />
                </div>
                {germanaudio && (
                  <div className="audio-place-holder mt-3">
                    <ListGroup as="ol" numbered className="theory-list">
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between list-card-play m-0"
                      >
                        <div className="ms-2 me-auto d-flex align-items-center">
                          <div className="icons-list">
                            <Image
                              src={Playicon}
                              className="custom-cursor-pointer img-play-card"
                            />
                          </div>
                          <div className="list-title">{germanaudio?.name}</div>
                        </div>

                        <div className="icons-list">
                          <Image
                            onClick={() => setgermanAudio()}
                            className="custom-cursor-pointer"
                            src={Deleteicon}
                          />
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                )}



              </Form.Group>
              <Form.Group
                className="mb-2 mt-5 model-btn text-center"
                controlId="formGridAddress2"
              >
                <Button
                  variant="primary"
                  size="lg"
                  onClick={handleCreateCourse}
                >
                  Submit
                </Button>
              </Form.Group>
            </Form>
          </Modal.Body>
        </Modal>
      )}

      <Modal
        show={editCourseCategory}
        onHide={() => {
          seteditCourseCategory(false);
          setCatTitle();
          setCatTitle2();
          setCatDescTitle();
          setCatDescTitle2();
          setImage();
          setImage2();
          setPrevImg();
          setPrevImg2();
        }}
        className="text-center meditation-model modal-content-80"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton className="border-0 p-0"></Modal.Header>
        <Modal.Title className="mb-4 px-3">Edit Category</Modal.Title>
        <Modal.Body>
          <Row>
            <Col lg={6}>
              <div className="category-label">
                <Form.Label>Edit Category (English Text)</Form.Label>
                <Form.Control
                  onChange={(e) => setCatTitle(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Category name"
                  value={catTitle}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="category-label">
                <Form.Label>Edit Category (German Text)</Form.Label>
                <Form.Control
                  onChange={(e) => setCatTitle2(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Kategoriename"
                  value={catTitle2}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div className="category-label">
                <Form.Label>Edit Description (English Text)</Form.Label>
                <Form.Control
                  onChange={(e) => setCatDescTitle(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Category name"
                  value={catDescTitle}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="category-label">
                <Form.Label>Edit Description (German Text)</Form.Label>
                <Form.Control
                  onChange={(e) => setCatDescTitle2(e.target.value)}
                  size="lg"
                  type="text"
                  placeholder="Kategoriename"
                  value={catDescTitle2}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Form.Group className="mb-4 px-3" controlId="formGridAddress2">
          <div className="upld-place-click">
            <p>{image ? image?.name || image : "Click to upload image"}</p>
            <Form.Control
              className="add-upld-hide add-icon-hide"
              type="file"
              placeholder="click to upload audio file"
              onChange={handleImgUpload}
            />
          </div>
        </Form.Group>
        <div className="img-upld-place add-image-upld">
          {prevImg && <img src={prevImg} />}
        </div>

        {/* <Form.Group className="mb-4 px-3" controlId="formGridAddress2">
          <div className="upld-place-click">
            <p>
              {image2 ? image2?.name : "Klicken Sie, um das Bild hochzuladen"}
            </p>
            <Form.Control
              className="add-upld-hide add-icon-hide"
              type="file"
              placeholder="Klicken Sie, um das Bild hochzuladen"
              onChange={handleImgUpload2}
            />
          </div>
        </Form.Group> */}
        <div className="img-upld-place add-image-upld">
          {prevImg2 && <img src={prevImg2} />}
        </div>
        <Modal.Footer className="m-auto border-0">
          <Button
            className="mb-5"
            variant="primary"
            size="lg"
            onClick={handleEditCategory}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      
    </>
  );
}
