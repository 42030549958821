import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Container,
  Row,
  Card,
  Col,
  Form,
  Button,
  Image,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Deleteicon from "../images/delete_icon.svg";
import Editicon from "../images/edit_icon.svg";
import {
  createReflection,
  editReflection,
  getReflectionDetails,
} from "../redux/actions/adminActions";
import ReflectionModal from "../components/selfReflection/ReflectionModal";
import { FiArrowLeft, FiPlus } from "react-icons/fi";
import { useParams } from "react-router-dom";
import EditReflectionModal from "../components/selfReflection/EditReflectionModal";

const EditSelfReflection = () => {
  const { id } = useParams();
  const [germenAudio, setGermenAudio] = useState(false);
  const [audio, setAudio] = useState(false);
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [editQuestion, setEditQuestion] = useState();
  const [editQuestionIndex, setEditQuestionIndex] = useState();
  const dispatch = useDispatch();

  const initialState = {
    0: false,
    1: false,
    2: false,
  };

  const [radio, setRadio] = useState(initialState);
  const navigate = useNavigate();
  const reflectionDetails = useSelector(
    (state) => state.reflectionSlice.reflectionDetails
  );

  console.log(reflectionDetails, "reflectionDetails");

  useEffect(() => {
    dispatch(getReflectionDetails(id));
  }, []);

  const handleJourneyType = (status, key) => {
    setRadio(() => {
      const newState = { ...initialState };
      newState[key] = status;
      return newState;
    });
  };

  useEffect(() => {
    if (reflectionDetails) {
      handleJourneyType(true, reflectionDetails?.journeytype);
      setQuestions(reflectionDetails?.questions);
    }
  }, [reflectionDetails]);

  const handleRemoveQuestion = (index) => {
    setQuestions((prev) => {
      const tempArr = [...prev];
      tempArr.splice(index, 1);
      return tempArr;
    });
  };

  const handleEditQuestion = (index) => {
    setEditQuestion(questions[index]);
    setEditQuestionIndex(index);
    setEditShow(true);
  };

  return (
    <>
      <Layout>
        <Container fluid>
          <div className="backBtn">
            <a onClick={() => navigate(-1)}>
              <h5 className="text-primary-clr backpad cursor-pointer">
                <FiArrowLeft /> Back
              </h5>
            </a>
          </div>
          <Row>
            <Card className="border-0 card-user-detail rounded-card meditation-info px-4 mt-4 mb-5">
              <div className="top-flex justify-content-between">
                <div>
                  <h2>Edit New Reflection</h2>
                </div>
              </div>
              <Container>
                <Row>
                  <Card className="border-0 p-4 rounded-card self-reflection-body self-reflection-main mt-5 mb-5 add-refl-box-p">
                    <Row>
                      <Col md={12} className="mx-auto">
                        <Formik
                          enableReinitialize
                          initialValues={{
                            title: reflectionDetails?.title,
                            subTitle: reflectionDetails?.subTitle,
                            germen_title: reflectionDetails?.germen_title,
                            germen_subTitle: reflectionDetails?.germen_subTitle,
                          }}
                          validate={(values) => {
                            const errors = {};
                            if (!values.title) {
                              errors.title = "Required*";
                            }
                            if (!values.subTitle) {
                              errors.subTitle = "Required*";
                            }
                            if (!values.germen_title) {
                              errors.germen_title = "Required*";
                            }
                            if (!values.germen_subTitle) {
                              errors.germen_subTitle = "Required*";
                            }
                            return errors;
                          }}
                          onSubmit={(values, { setSubmitting }) => {
                            if (!Object.values(radio).includes(true)) {
                              toast.warning("Please select exercise type");
                              setSubmitting(false);
                              return;
                            }
                            console.log(radio,"radio")

                            if (questions?.length < 1 && radio[0]) {
                              toast.warning("Please add questions");
                              setSubmitting(false);
                              return;
                            }
                            const jtype = Object.keys(radio).find(
                              (key) => radio[key]
                            );

                            const formData = new FormData();
                            formData.append("id", id);
                            formData.append(
                              "audio",
                              audio ? audio : reflectionDetails?.audio
                            );
                            formData.append(
                              "germen_audio",
                              germenAudio
                                ? germenAudio
                                : reflectionDetails?.germen_audio
                            );
                            formData.append("journeytype", jtype);
                            formData.append("title", values.title);
                            formData.append("subTitle", values.subTitle);
                            formData.append(
                              "germen_title",
                              values.germen_title
                            );
                            formData.append(
                              "germen_subTitle",
                              values.germen_subTitle
                            );
                            formData.append(
                              "questions",
                              JSON.stringify(questions)
                            );

                            dispatch(editReflection(formData))
                              .then(({ payload }) => {
                                if (payload?.success) {
                                  toast.success(payload?.message);
                                  navigate("/admin/self-reflection");
                                } else if (!payload?.success) {
                                  toast.error(payload?.message);
                                }
                              })
                              .catch((err) => console.log(err));

                            setSubmitting(false);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                          }) => (
                            <Form
                              onSubmit={handleSubmit}
                              className="course-details"
                            >
                              <Form.Group
                                className="mb-4"
                                controlId="formGridEmail"
                              >
                                <Row>
                                  <Col lg={6}>
                                    <Form.Label>
                                      Reflection Journey Title (English Text)
                                    </Form.Label>

                                    <Form.Control
                                      type="text"
                                      placeholder="Reflection Journey Title"
                                      name="title"
                                      onChange={handleChange}
                                      value={values?.title}
                                    />
                                    <span className="formik-errors">
                                      {errors.title &&
                                        touched.title &&
                                        errors.title}
                                    </span>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Label>
                                      Titel der Reflexionsreise (German Text)
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Titel der Reflexionsreise"
                                      name="germen_title"
                                      onChange={handleChange}
                                      value={values?.germen_title}
                                    />
                                    <span className="formik-errors">
                                      {errors.germen_title &&
                                        touched.germen_title &&
                                        errors.germen_title}
                                    </span>
                                  </Col>
                                </Row>
                              </Form.Group>

                              <Form.Group
                                className="mb-4"
                                controlId="formGridEmail"
                              >
                                <Row>
                                  <Col lg={6}>
                                    <Form.Label>
                                      Reflection Journey Sub Title (English
                                      Text)
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Reflection Journey Sub Title"
                                      name="subTitle"
                                      onChange={handleChange}
                                      value={values?.subTitle}
                                    />
                                    <span className="formik-errors">
                                      {errors.subTitle &&
                                        touched.subTitle &&
                                        errors.subTitle}
                                    </span>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Label>
                                      Untertitel „Reflection Journey“. (German
                                      Text)
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Untertitel „Reflection Journey“."
                                      name="germen_subTitle"
                                      onChange={handleChange}
                                      value={values?.germen_subTitle}
                                    />
                                    <span className="formik-errors">
                                      {errors.germen_subTitle &&
                                        touched.germen_subTitle &&
                                        errors.germen_subTitle}
                                    </span>
                                  </Col>
                                </Row>
                              </Form.Group>

                              <Form.Group
                                className="mb-4"
                                controlId="formGridAddress2"
                              >
                                <Row>
                                  <Col lg={6}>
                                    <div className="upld-audio-title">
                                      Upload Audio file (English File)
                                    </div>
                                    <div className="upld-place-click">
                                      <p>
                                        {audio
                                          ? audio?.name
                                          : reflectionDetails?.audio?.split(
                                              "--"
                                            )[1]}
                                      </p>
                                      <Form.Control
                                        className="add-upld-hide upld-audio-hide"
                                        type="file"
                                        placeholder="click to upload audio file"
                                        onChange={(e) =>
                                          setAudio(e.target.files[0])
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="upld-audio-title">
                                      Audiodatei hochladen (German File)
                                    </div>
                                    <div className="upld-place-click">
                                      <p>
                                        {germenAudio
                                          ? germenAudio?.name
                                          : reflectionDetails?.germen_audio?.split(
                                              "--"
                                            )[1]}
                                      </p>
                                      <Form.Control
                                        className="add-upld-hide upld-audio-hide"
                                        type="file"
                                        placeholder="Klicken Sie hier, um die Audiodatei hochzuladen"
                                        onChange={(e) =>
                                          setGermenAudio(e.target.files[0])
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <div className="audio-place-holder mt-5">
                                  <div className="upld-audio-title">
                                    Exercise
                                  </div>

                                  <Row>
                                    {[
                                      "Questionnaire",
                                      "Story Timeline",
                                      "Wheel of Life",
                                    ]?.map((text, i) => {
                                      return (
                                        <Col key={i} md={4}>
                                          <div className="option-add-nre-refl">
                                            <div className="select-refl">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={(e) =>
                                                  handleJourneyType(
                                                    e.target.checked,
                                                    i
                                                  )
                                                }
                                                checked={radio[i]}
                                                id="flexCheckDefault"
                                              />
                                              {text}
                                            </div>
                                          </div>
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                </div>
                              </Form.Group>
                              {Object.keys(radio).find((key) => radio[key]) ==
                                0 && (
                                <div className="refl-question-card mt-5">
                                  <div className="custom-scroll">
                                    <div className="add-btn-refl">
                                      {/* <img
                                    onClick={() => setShow(true)}
                                    src={
                                      require("../images/add-icon.svg").default
                                    }
                                  /> */}

                                      <Button
                                        onClick={() => setShow(true)}
                                        className="add-category-btn new-self-ref"
                                        variant="primary"
                                      >
                                        <FiPlus /> Add New
                                      </Button>
                                    </div>
                                    <ReflectionModal
                                      show={show}
                                      setShow={setShow}
                                      // questions={questions}
                                      // addQuestion={(data) =>
                                      //   setQuestions((prev) => {
                                      //     let tempArr = [...prev];
                                      //     tempArr.push(data);
                                      //     return tempArr;
                                      //   })
                                      // }
                                      addQuestion={(data) =>
                                        setQuestions((prev) => {
                                          let tempArr = [...prev];

                                          data.question =
                                            `Q${tempArr?.length + 1}: ` +
                                            data.question;
                                          tempArr.push(data);
                                          return tempArr;
                                        })
                                      }
                                    />
                                    <EditReflectionModal
                                      show={editShow}
                                      setShow={setEditShow}
                                      givenQuestion={editQuestion}
                                      editQuestion={(data) =>
                                        setQuestions((prev) => {
                                          let tempArr = [...prev];
                                          tempArr[editQuestionIndex] = data;
                                          return tempArr;
                                        })
                                      }
                                    />
                                    {questions?.map((ques, i) => {
                                      const quesno = i + 1;
                                      if (ques.type == 0) {
                                        return (
                                          <div className="single_answer d-flex justify-content-between question-btn">
                                            <p className="card-q">
                                              {/* {`Q${quesno}: ${ques?.question}`} (Free-text) */}
                                              {ques?.question} (Free-text)
                                            </p>
                                            {/* <p className="card-a">
                                  <span>A1-</span>Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry.
                                </p> */}
                                            <div className="icons-list">
                                              <Image
                                                onClick={() =>
                                                  handleEditQuestion(i)
                                                }
                                                className="cursor-pointer"
                                                src={Editicon}
                                              />
                                              <Image
                                                onClick={() =>
                                                  handleRemoveQuestion(i)
                                                }
                                                className="cursor-pointer"
                                                src={Deleteicon}
                                              />
                                            </div>
                                          </div>
                                        );
                                      } else if (ques.type == 1) {
                                        return (
                                          <div className="multiple_answer">
                                            <div className="d-flex justify-content-between question-btn">
                                              <p className="card-q">
                                                {/* {`Q${quesno}: ${ques?.question}`} */}
                                                {ques?.question}
                                              </p>
                                              <div className="icons-list">
                                                <Image
                                                  onClick={() =>
                                                    handleEditQuestion(i)
                                                  }
                                                  className="cursor-pointer"
                                                  src={Editicon}
                                                />
                                                <Image
                                                  onClick={() =>
                                                    handleRemoveQuestion(i)
                                                  }
                                                  className="cursor-pointer"
                                                  src={Deleteicon}
                                                />
                                              </div>
                                            </div>
                                            <Row>
                                              <Col lg={6}>
                                                {ques?.answers?.map(
                                                  (ans, index) => {
                                                    return (
                                                      <p
                                                        key={index}
                                                        className={`card-a ${
                                                          ans?.key ==
                                                            ques?.correctans &&
                                                          "correct"
                                                        }`}
                                                      >
                                                        <span>
                                                          {ans?.key}:{" "}
                                                        </span>
                                                        {ans?.option}
                                                      </p>
                                                    );
                                                  }
                                                )}
                                              </Col>
                                              <Col lg={6}>
                                                {ques?.germenAnswers?.map(
                                                  (ans, index) => {
                                                    return (
                                                      <p
                                                        key={index}
                                                        className={`card-a ${
                                                          ans?.key ==
                                                            ques?.correctans &&
                                                          "correct"
                                                        }`}
                                                      >
                                                        <span>
                                                          {ans?.key}:{" "}
                                                        </span>
                                                        {ans?.option}
                                                      </p>
                                                    );
                                                  }
                                                )}
                                              </Col>
                                            </Row>
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                </div>
                              )}
                              {/* <p className="card-a">
                                        <span>A2-</span>Lorem Ipsum is simply
                                        dummy text of the
                                      </p>
                                      <p className="card-a correct">
                                        <span>A3-</span>Lorem Ipsum is simply
                                        dummy text of the
                                      </p>
                                      <p className="card-a">
                                        <span>A4-</span>Lorem Ipsum is simply
                                        dummy text of the
                                      </p> */}
                              {/* <ReflectionQuestions /> */}
                              {errors.password &&
                                touched.password &&
                                errors.password}
                              <div className="d-flex justify-content-center mt-3">
                                <button
                                  type="submit"
                                  className="new-self-ref btn btn-primary"
                                  disabled={isSubmitting}
                                >
                                  Submit
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </Col>
                    </Row>
                  </Card>
                </Row>
              </Container>
            </Card>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default EditSelfReflection;
