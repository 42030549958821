import { useNavigate } from "react-router-dom";
import { onMessageListener } from "../../services/firebaseConfig";
import toast from "react-hot-toast";

const PushNotification = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    window.location.replace("/admin/coaches");
  };

  onMessageListener()
    .then((payload) => {
      //   toast.success(payload?.notification?.title);
      toast(
        <div className="cursor-pointer" onClick={handleClick}>
          <p>
            <b>{payload?.notification?.title}</b>
          </p>
          <p>{payload?.notification?.body}</p>
        </div>
      );
      //   setNotification({
      //     title: payload?.notification?.title,
      //     body: payload?.notification?.body,
      //   });
    })
    .catch((err) => console.log("failed: ", err));

  //   return <div>PushNotification</div>;
};

export default PushNotification;
