import React from "react";
import { Button } from "react-bootstrap";
import { FiArrowDown } from "react-icons/fi";
import * as XLSX from 'xlsx';


export default function DownloadCsv({ data }) {
    // Function to convert an array of objects to XLSX format
    const convertArrayOfObjectsToXLSX = (array) => {
        const worksheet = XLSX.utils.json_to_sheet(array);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        return excelBuffer;
    };

    // Function to trigger the download
    const downloadExcel = (array) => {
        const excelBuffer = convertArrayOfObjectsToXLSX(array);
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'data.xlsx');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };


    return <Button onClick={() => downloadExcel(data)}
                  className="text-primary-clr align-item-center table-head-btn"
                  size="lg"
                  variant="light"
                >
                  <FiArrowDown /> Download
                </Button>
   
}
