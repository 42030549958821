import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "../../services/adminApi";
import { toast } from "react-toastify";
import { create } from "@mui/material/styles/createTransitions";

const dashboard = createAsyncThunk("dashboard", async () => {
  const { data } = await adminApi.get("/dashboard");
  return data;
});

const getUsers = createAsyncThunk(
  "getUsers",
  async ({ page, limit, search }) => {
    const { data } = await adminApi.get(
      `/getUsers?page=${page}&limit=${limit}&search=${search}`
    );
    return data;
  }
);

export const getUsersdownload = createAsyncThunk(
  "getUsersdownload",
  async () => {
    const { data } = await adminApi.get(
      `/getUsers`
    );
    return data;
  }
);

const getUserDetails = createAsyncThunk("getUserDetails", async (id) => {
  const { data } = await adminApi.get(`/getUserDetails/?user_id=${id}`);
  return data;
});

const editUserDetails = createAsyncThunk(
  "editUserDetails",
  async (formdata) => {
    const { data } = await adminApi.put("/editUserDetails", formdata);
    return data;
  }
);

const changeUserPass = createAsyncThunk("changeUserPass", async (passObj) => {
  const { data } = await adminApi.put("/changeUserPass", passObj);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});



const deactivateUser = createAsyncThunk("deactivateUser", async (deObj) => {
  const { data } = await adminApi.put("/deactivateUserAccount", deObj);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const deleteUser = createAsyncThunk("deleteUser", async (id) => {
  const { data } = await adminApi.delete(`/deleteUserAccount?user_id=${id}`);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const getCoaches = createAsyncThunk(
  "getCoaches",
  async ({ page, limit, search }) => {
    const { data } = await adminApi.get(
      `/getCoaches?page=${page}&limit=${limit}&search=${search}`
    );
    return data;
  }
);

const verifyCoach = createAsyncThunk("verifyCoach", async (verifyObj) => {
  const { data } = await adminApi.post("/verifyCoach", verifyObj);
  return data;
});

const getCoachDetails = createAsyncThunk("getCoachDetails", async (id) => {
  const { data } = await adminApi.get(`/getCoachDetails?id=${id}`);
  return data;
});

const getVerificationUsers = createAsyncThunk(
  "getVerificationUsers",
  async () => {
    const { data } = await adminApi.get("/getVerificationUsers");
    return data;
  }
);

const getReports = createAsyncThunk("getReports", async () => {
  const { data } = await adminApi.get("/getReports");
  return data;
});

const deleteReport = createAsyncThunk("deleteReport", async (id) => {
  const { data } = await adminApi.delete(`/deleteReport?id=${id}`);
  return data;
});

const changeUserStatus = createAsyncThunk(
  "changeUserStatus",
  async (statusObj) => {
    const { data } = await adminApi.post("/changeUserStatus", statusObj);
    return data;
  }
);

const changeReportUserStatus = createAsyncThunk(
  "changeReportUserStatus",
  async (statusObj) => {
    const { data } = await adminApi.post("/changeUserStatus", statusObj);
    return data;
  }
);

const addNotification = createAsyncThunk("addNotification", async (notObj) => {
  const { data } = await adminApi.post("/addNotification", notObj);
  return data;
});

const getNotifications = createAsyncThunk("getNotifications", async (search) => {
  const { data } = search ? await adminApi.get(`/getAdminNotifications?search=${search}`) : await adminApi.get("/getAdminNotifications");
  return data;
});

const deleteNotification = createAsyncThunk(
  "deleteNotification",
  async (id) => {
    const { data } = await adminApi.delete(`/deleteNotification?id=${id}`);
    return data;
  }
);

const getSupports = createAsyncThunk("getSupports", async () => {
  const { data } = await adminApi.get("/getSupports");
  return data;
});

const changeSupportStatus = createAsyncThunk(
  "changeSupportStatus",
  async (statusObj) => {
    const { data } = await adminApi.post("/changeSupportStatus", statusObj);
    return data;
  }
);

const getCourseCategories = createAsyncThunk(
  "getCourseCategories",
  async () => {
    const { data } = await adminApi.get("/getCourseCategories");
    return data;
  }
);

const createCourseCategory = createAsyncThunk(
  "createCourseCategory",
  async (formdata) => {
    const { data } = await adminApi.post("/createCourseCategory", formdata);
    return data;
  }
);

const getReflections = createAsyncThunk("getReflections", async ({categoryId}) => {
  const { data } = await adminApi.get(`/getReflections?categoryId=${categoryId}`);
  return data;
});

const createReflection = createAsyncThunk(
  "createReflection",
  async (formdata) => {
    const { data } = await adminApi.post("/createReflection", formdata);
    return data;
  }
);

const editReflection = createAsyncThunk("editReflection", async (formdata) => {
  const { data } = await adminApi.post("/editReflection", formdata);
  return data;
});

const deleteReflection = createAsyncThunk("deleteReflection", async (id) => {
  const { data } = await adminApi.delete(`/deleteReflection?id=${id}`);
  return data;
});

const deleteCourseCategory = createAsyncThunk(
  "deleteCourseCategory",
  async (id) => {
    const { data } = await adminApi.delete(`/deleteCourseCategory?id=${id}`);
    return data;
  }
);

const deleteCategoryById = createAsyncThunk(
  "deleteCategoryById",
  async (id) => {
    const { data } = await adminApi.delete(`/deleteCategoryById/${id}`);
    return data;
  }
);


const createCourse = createAsyncThunk(
  "createCourse",
  async (formdata, { dispatch }) => {
    const { data } = await adminApi.post("/createCourse", formdata);
    if (data?.success) {
      toast.success(data?.message);
      dispatch(getCourseCategories());
    } else if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

const editCourse = createAsyncThunk("editCourse", async (formdata) => {
  const { data } = await adminApi.put("/editCourse", formdata);
  return data;
});

const deleteCourse = createAsyncThunk("deleteCourse", async (id) => {
  const { data } = await adminApi.delete(`/deleteCourse?id=${id}`);
  return data;
});

const getCourses = createAsyncThunk("getCourses", async (id) => {
  const { data } = await adminApi.get(`/getCourses?id=${id}`);
  return data;
});

const getReflectionDetails = createAsyncThunk(
  "getReflectionDetails",
  async (id) => {
    const { data } = await adminApi.get(`/getReflectionDetails?id=${id}`);
    return data;
  }
);

const deactivateCoach = createAsyncThunk("deactivateCoach", async (deObj) => {
  const { data } = await adminApi.put("/deactivateCoach", deObj);
  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);
  return data;
});

const deleteCoach = createAsyncThunk("deleteCoach", async (id) => {
  const { data } = await adminApi.delete(`/deleteCoachAccount?coach_id=${id}`);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);
  return data;
});

const changeCoachPass = createAsyncThunk("changeCoachPass", async (passObj) => {
  const { data } = await adminApi.put("/changeCoachPass", passObj);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const createfaq = createAsyncThunk("createfaq", async (obj) => {
  const { data } = await adminApi.post("/createfaq", obj);
  return data;
});

const getfaqs = createAsyncThunk("getfaqs", async (search) => {
  
  const { data } =  search ? await  adminApi.get(`/getfaqs?search=${search}`):await  adminApi.get("/getfaqs");
  return data;
});

const deletefaq = createAsyncThunk("deletefaq", async (id) => {
  const { data } = await adminApi.delete(`/deletefaq?id=${id}`);
  return data;
});

const getfaqDetails = createAsyncThunk("getfaqDetails", async (id) => {
  const { data } = await adminApi.get(`/getfaqDetails?id=${id}`);
  return data;
});
const editfaq = createAsyncThunk("editfaq", async (obj) => {
  const { data } = await adminApi.put("/editfaq", obj);
  return data;
});
const createhelp = createAsyncThunk("createhelp", async (obj) => {
  const { data } = await adminApi.post("/createhelp", obj);
  return data;
});

const gethelps = createAsyncThunk("gethelps", async () => {
  const { data } = await adminApi.get("/gethelps");
  return data;
});

const deletehelp = createAsyncThunk("deletehelp", async (id) => {
  const { data } = await adminApi.delete(`/deletehelp?id=${id}`);
  return data;
});

const gethelpDetails = createAsyncThunk("gethelpDetails", async (id) => {
  const { data } = await adminApi.get(`/gethelpDetails?id=${id}`);
  return data;
});
const edithelp = createAsyncThunk("edithelp", async (obj) => {
  const { data } = await adminApi.put("/edithelp", obj);
  return data;
});



const changePass = createAsyncThunk(
  "changePass",
  async (data, thunkAPI) => {
      const response = await adminApi.put("/changePass", data);
      return response.data;
  }
);

const createImprint = createAsyncThunk("createImprint", async (obj) => {
  const { data } = await adminApi.post("/createImprint", obj);
  return data;
});

const getImprint = createAsyncThunk("getImprint", async () => {
  const { data } = await adminApi.get("/getImprint");
  return data;
});

const createCategory = createAsyncThunk(
  "createCategory",
  async (formdata) => {
    const { data } = await adminApi.post("/createCategory", formdata);
    return data;
  }
);
export const getCategorybyid = createAsyncThunk(
  "getCategorybyid",
  async (formdata) => {
    const { data } = await adminApi.get(`/getCategoryByid?id=${formdata}`);
    return data;
  }
);
export const getMeditationbyid = createAsyncThunk(
  "getMeditationbyid",
  async (formdata) => {
    const { data } = await adminApi.get(`/getCourseCategoriesById?id=${formdata}`);
    return data;
  }
);
export const EditCategorybyid = createAsyncThunk(
  "EditCategorybyid",
  async (formdata) => {
    const { data } = await adminApi.post(`/editCategory`,formdata);
    return data;
  }
);
export const EditCourseCategorybyid = createAsyncThunk(
  "EditCourseCategorybyid",
  async (formdata) => {
    const { data } = await adminApi.post(`/editCourseCategory`,formdata);
    return data;
  }
);


const getCategory = createAsyncThunk(
  "getCategory",
  async () => {
    const { data } = await adminApi.get("/getCategory");
    return data;
  }
);

const createAudioFile = createAsyncThunk("createAudioFile", async (obj) => {
  const { data } = await adminApi.post("/createAudioFile", obj);
  return data;
});

const getAudioFile = createAsyncThunk("getAudioFile", async () => {
  const { data } = await adminApi.get("/getAudioFile");
  return data;
});


export {
  dashboard,
  getUsers,
  getUserDetails,
  editUserDetails,
  changeUserPass,
  deactivateUser,
  deleteUser,
  getVerificationUsers,
  getReports,
  deleteReport,
  changeUserStatus,
  addNotification,
  getNotifications,
  deleteNotification,
  getSupports,
  changeSupportStatus,
  changeReportUserStatus,
  getCoaches,
  verifyCoach,
  getCoachDetails,
  getCourseCategories,
  getReflections,
  deleteCourseCategory,
  createCourse,
  editCourse,
  getCourses,
  deleteCourse,
  createCourseCategory,
  deleteReflection,
  createReflection,
  editReflection,
  getReflectionDetails,
  deactivateCoach,
  deleteCoach,
  changeCoachPass,
  createfaq,
  getfaqs,
  deletefaq,
  getfaqDetails,
  editfaq,
  createhelp,
  gethelps,
  deletehelp,
  gethelpDetails,
  edithelp,
  changePass,
  createImprint,
  getImprint,
  createCategory,
  getCategory,
  createAudioFile,
  getAudioFile,
  deleteCategoryById
};
