import Login from "./pages/Login";
import User from "./pages/User";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import CoachDetail from "./components/user/CoachDetail";
import Coaches from "./pages/Coaches";
import Report from "./pages/Report";
import Notification from "./pages/Notification";
import ChangePassword from "./pages/ChangePassword";
import Meditation from "./pages/Meditation";
import SelfReflection from "./pages/SelfReflection";
import AddNewReflection from "./pages/AddNewReflection";
import QuestionDetails from "./pages/QuestionDetails";
import PrivateRoute from "./routes/PrivateRoute";
import Courses from "./pages/Courses";
import UserDetails from "./pages/UserDetails";
import EditSelfReflection from "./pages/EditSelfReflection";
import PushNotification from "./components/elements/PushNotification";
import FAQs from "./pages/FAQs";
import CreateFAQ from "./components/FAQs/CreateFAQ";
import EditFAQ from "./components/FAQs/EditFAQ";
import Help from "./pages/Help";
import CreateHelp from "./components/Help/CreateHelp";
import EditHelp from "./components/Help/EditHelp";
import Imprint from "./pages/Imprint";
import Category from "./pages/CategoryPage";
import WelcomeAudio from "./pages/WelcomeAudio";
import AddWelcomeAudio from "./pages/AddWelcomeAudio";
function App() {
  return (
    <>
      <Toaster />
      <BrowserRouter>
        <PushNotification />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/*" element={<Navigate to="/" />} />
          <Route path="admin/*" element={<Navigate to="/admin/dashboard" />} />
          <Route path="/admin" element={<PrivateRoute />}>
            <Route path="users" element={<User />} />
            <Route path="userdetails/:id" element={<UserDetails />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="coachdetail/:id" element={<CoachDetail />} />
            <Route path="coaches" element={<Coaches />} />
            <Route path="report" element={<Report />} />
            <Route path="notification" element={<Notification />} />
            <Route path="meditation" element={<Meditation />} />
            <Route path="courses/:id/:name" element={<Courses />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="self-reflection/:id" element={<SelfReflection />} />
            <Route path="add-new-reflection/:id" element={<AddNewReflection />} />
            <Route path="faqs" element={<FAQs />} />
            <Route path="imprint" element={<Imprint />} />

            <Route path="createfaqs" element={<CreateFAQ />} />
            <Route path="editfaqs/:id" element={<EditFAQ />} />
            <Route path="help" element={<Help />} />
            <Route path="createhelp" element={<CreateHelp />} />
            <Route path="edithelp/:id" element={<EditHelp />} />
            <Route
              path="editSelfReflection/:id"
              element={<EditSelfReflection />}
            />
            
            <Route path="question-details" element={<QuestionDetails />} />
            <Route path="category" element={<Category />} />
            <Route path="welcome-audio" element={<WelcomeAudio />} />
            <Route path="Add-welcome-audio" element={<AddWelcomeAudio />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
