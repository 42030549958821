import React, { useRef, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Card,
  Container,
  Row,
  ListGroup,
  Image,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import { FiArrowLeft } from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";
import Deleteicon from "../images/delete_icon.svg";
import Editicon from "../images/edit_icon.svg";
import Playicon from "../images/play_icon.svg";
import playpop from "../images/plypop.png";
import pause from "../images/pause.png";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCourse,
  editCourse,
  getCourses,
} from "../redux/actions/adminActions";

export default function Courses() {
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [showAudio, setShowAudio] = useState(false);
  const [delId, setDelId] = useState();
  const [germanyTitle, setgermanyTitle] = useState("");
  const [showDel, setShowDel] = useState();
  const [audio, setAudio] = useState();
  const [germenaudio, setgermenAudio] = useState();
  const [title, setTitle] = useState();
  const [editAudio, setEditAudio] = useState();
  const [editgermanAudio, setEditgermanAudio] = useState();
  const [editCourseId, setEditCourseId] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const [editCourseTitle, setEditCourseTitle] = useState();
  const [audioName, setAudioName] = useState();
  const [germanaudioName, setgermanAudioName] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const { id, name } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.courseSlice.courses);

  useEffect(() => {
    dispatch(getCourses(id));
  }, []);

  const handleDeleteCourse = () => {
    dispatch(deleteCourse(delId));
    setShowDel(false);
  };

  console.log(editCourseTitle,"editCourseTitle")

  const handleEditCourse = () => {
    const formdata = new FormData();
    formdata.append("course_id", editCourseId);
    formdata.append("title", editCourseTitle);
    formdata.append("germanyTitle",germanyTitle)
    editAudio && formdata.append("audio", editAudio);
    editgermanAudio && formdata.append("germen_audio", editgermanAudio);

    dispatch(editCourse(formdata));

    setShowEdit(false);
  };

  // const handleClose = () => setShowAudio(false);

  const handleClose = () => {
    setShowAudio(false);
    // Stop the audio when the modal is closed
    setIsPlaying(false);
  };

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
    const audioElement = document.getElementById("audioElement");

    if (isPlaying) {
      audioElement.pause();
    } else {
      audioElement.play();
    }
  };

  return (
    <>
      <Layout>
        <div className="backBtn">
          <Link onClick={() => navigate(-1)}>
            <h5 className="text-primary-clr backpad">
              <FiArrowLeft /> Back
            </h5>
          </Link>
        </div>
        <Container>
          <Row>
            <Card className="border-0 p-4 rounded-card self-reflection-body">
              <h2 className="mb-5">{name}</h2>
              <ListGroup as="ol" numbered className="theory-list">
                {courses?.length > 0 &&
                  courses?.map((course, i) => {
                    return (
                      <ListGroup.Item
                        key={course?._id}
                        as="li"
                        className="d-flex justify-content-between list-card-play"
                      >
                        <div className="ms-2 me-auto d-flex align-items-center">
                          <div className="icon-number">{i + 1}</div>
                          <div className="list-title">{course?.title}</div>
                        </div>
                        <div className="icons-list">
                          <Image
                            src={Playicon}
                            className="img-play-card"
                            onClick={() => {
                              setShowAudio(true);
                              setAudio(course?.audio);
                              setgermenAudio(course?.germen_audio);
                              setTitle(course?.title);
                            }}
                          />
                          <Image
                            onClick={() => {
                              setShowEdit(true);
                              setEditCourseId(course?._id);
                              setAudioName(course?.audio?.split("--")[1]);
                              setgermanAudioName(course?.germen_audio?.split("--")[1]);
                              setEditCourseTitle(course?.title);
                            }}
                            className="cursor-pointer"
                            src={Editicon}
                          />
                          <Image
                            onClick={() => {
                              setShowDel(true);
                              setDelId(course?._id);
                            }}
                            className="cursor-pointer"
                            src={Deleteicon}
                          />
                        </div>
                      </ListGroup.Item>
                    );
                  })}
              </ListGroup>
            </Card>
          </Row>
        </Container>
      </Layout>
      {/* Play Modal  {/* <Modal show={showAudio} onHide={handleClose} centered>
          <Modal.Header closeButton className="border-0 p-0"></Modal.Header>
          <Modal.Body className="text-center border-0">
            <Modal.Title className="mb-5">{title}</Modal.Title>
            <Image src={playpop} width="35%" />
            <audio controls className="mb-4 mt-5">
              <source
                //   src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
                src={BASE_URL + audio}
                type="audio/wav"
              />
            </audio>
          </Modal.Body>
        </Modal> */}

      {audio && (
        <Modal show={showAudio} onHide={handleClose} centered>
          <Modal.Header closeButton className="border-0 p-0"></Modal.Header>
          <Modal.Body className="text-center border-0">
            <Modal.Title className="mb-5">{title}</Modal.Title>

            {isPlaying ? (
              <Image onClick={handlePlayPause} src={pause} width="35%" />
            ) : (
              <Image onClick={handlePlayPause} src={playpop} width="35%" />
            )}

            <audio
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              id="audioElement"
              controls
              className="mb-4 mt-5"
            >
              <source src={BASE_URL + audio} type="audio/wav" />
            </audio>
          </Modal.Body>
        </Modal>
      )}
      {/* Delete Modal */}
      {delId && (
        <Modal
          show={showDel}
          onHide={() => setShowDel(false)}
          className="text-center meditation-model modal-content-80"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton className="border-0 p-0"></Modal.Header>
          <Modal.Title className="mb-4 px-3">Delete Course</Modal.Title>
          <Modal.Body>
            <p>Are you sure you want to delete this Course?</p>
          </Modal.Body>

          <Modal.Footer className="m-auto border-0">
            <Button
              className="mb-5"
              variant="primary"
              size="lg"
              onClick={handleDeleteCourse}
            >
              Yes
            </Button>
            <Button
              className="mb-5"
              variant="primary"
              size="lg"
              onClick={() => setShowDel(false)}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/* Edit Modal */}
      {editCourseId && (
        <Modal
          show={showEdit}
          onHide={() => {
            setShowEdit(false);
            // setCourseTitle();
            // setAudio();
          }}
          className="text-center meditation-model modal-content-80"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton className="border-0 p-0"></Modal.Header>
          <Modal.Title className="card-btn-add">Edit Course</Modal.Title>
          <Modal.Body className="text-left">
            <Form className="course-details">
              <Form.Group className="mb-4" controlId="formGridEmail">
                <Form.Label>Course Title (English)</Form.Label>
                <Form.Control
                  onChange={(e) => setEditCourseTitle(e.target.value)}
                  type="text"
                  placeholder="Title"
                  value={editCourseTitle}
                />
              </Form.Group>
              <Form.Group className="mb-4" controlId="formGridEmail">
                <Form.Label>Course Title (German)</Form.Label>
                <Form.Control
                  onChange={(e) => setgermanyTitle(e.target.value)}
                  type="text"
                  placeholder="Titel"
                  value={germanyTitle}
                />
              </Form.Group>

              <Form.Group className="mb-4" controlId="formGridAddress2">
                <div className="upld-place-click">
                <Form.Control
                  className="add-upld-hide"
                  type="file"
                  placeholder="click to upload audio file"
                  onChange={(e) => setEditAudio(e.target.files[0])}
                />
                  <p>Click to upload english audio file</p>
                </div>
                

                <div className="audio-place-holder mt-3 display-hidden">
                  <ListGroup as="ol" numbered className="theory-list">
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between list-card-play m-0"
                    >
                      <div className="ms-2 me-auto d-flex align-items-center">
                        <div className="icons-list">
                          <Image
                            src={Playicon}
                            className="custom-cursor-pointer img-play-card"
                          />
                        </div>
                        <div className="list-title">
                          {editAudio ? editAudio?.name : audioName}
                        </div>
                      </div>
                      {/* <div className="icons-list">
                        <Image
                          onClick={() => setAudio()}
                          className="custom-cursor-pointer"
                          src={Deleteicon}
                        />
                      </div> */}
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </Form.Group>



              <Form.Group className="mb-4" controlId="formGridAddress2">
                <div className="upld-place-click">
                <Form.Control
                  className="add-upld-hide"
                  type="file"
                  placeholder="click to upload audio file"
                  onChange={(e) => setEditgermanAudio(e.target.files[0])}
                />
                  <p>Click to upload german audio file</p>
                </div>
                

                <div className="audio-place-holder mt-3 display-hidden">
                  <ListGroup as="ol" numbered className="theory-list">
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between list-card-play m-0"
                    >
                      <div className="ms-2 me-auto d-flex align-items-center">
                        <div className="icons-list">
                          <Image
                            src={Playicon}
                            className="custom-cursor-pointer img-play-card"
                          />
                        </div>
                        <div className="list-title">
                          {editgermanAudio ? editgermanAudio?.name : germanaudioName}
                        </div>
                      </div>
                      {/* <div className="icons-list">
                        <Image
                          onClick={() => setAudio()}
                          className="custom-cursor-pointer"
                          src={Deleteicon}
                        />
                      </div> */}
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </Form.Group>
              <Form.Group
                className="mb-2 mt-5 model-btn text-center"
                controlId="formGridAddress2"
              >
                <Button variant="primary" size="lg" onClick={handleEditCourse}>
                  Submit
                </Button>
              </Form.Group>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
